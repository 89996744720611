{
  "accounting.account_balance": "Account balance",
  "accounting.accounting_dashboard": "Accounting dashboard",
  "accounting.dashboard_preview_description": "This is a preview of “Accounting dashboard” feature. We import accounting data from Catacloud, and show you a summary of the most important information, directly in Hoopit!",
  "accounting.expense": "Expense",
  "accounting.imported_from_catacloud": "Imported from Catacloud",
  "accounting.income": "Income",
  "accounting.open_invoices": "Open invoices",
  "accounting.page_description_1": "Did you know that you can integrate Hoopit with Catacloud accounting system?",
  "accounting.page_description_2": "Together with ECIT and Catacloud, we will provide automatic accounting of income and expenses, and a complete overview of your accounting data directly in Hoopit.",
  "accounting.request_more_information": "Request more information",
  "accounting.result": "Result",
  "accounting.results": "Results",
  "accounting.this_year": "this year",
  "accounting.unpaid_bills": "Unpaid bills",
  "action.accept": "Accept",
  "action.activate": "Activate",
  "action.add": "Add",
  "action.add_note": "Add note",
  "action.apply": "Apply",
  "action.apply_changes": "Apply changes",
  "action.approve": "Approve",
  "action.back": "Back",
  "action.back_to_login": "Back to login",
  "action.cancel": "Cancel",
  "action.change": "Change",
  "action.choose_all": "Choose all",
  "action.clear": "Clear",
  "action.click_here": "Press here",
  "action.click_to_view": "Click to view",
  "action.close": "Close",
  "action.complete": "Complete",
  "action.confirm": "Confirm",
  "action.continue": "Continue",
  "action.copy": "Copy",
  "action.copy_link": "By link",
  "action.create": "Create",
  "action.delete": "Delete",
  "action.delete_all": "Delete all",
  "action.download": "Download",
  "action.edit": "Edit",
  "action.embed_with_iframe": "Embed with iframe",
  "action.enroll": "Enroll",
  "action.event_cancel": "Cancel",
  "action.export": "Export",
  "action.finalize": "Finalize",
  "action.ical_ics_link": "iCAL/iCS link",
  "action.invite": "Invite",
  "action.join_waiting_line": "Join waiting line",
  "action.load_more": "Load more",
  "action.login": "Log in",
  "action.logout": "Log out",
  "action.move_to_group": "Move users to group",
  "action.next": "Next",
  "action.no_go_back": "No, go back",
  "action.ok": "Ok",
  "action.publish": "Publish",
  "action.register": "Register",
  "action.reject": "Reject",
  "action.remind": "Remind",
  "action.remove": "Remove",
  "action.reopen": "Reopen",
  "action.resend_code": "Resend code",
  "action.reset_filters": "Reset filters",
  "action.save": "Save",
  "action.save_changes": "Save changes",
  "action.search": "Search",
  "action.see_more": "See more",
  "action.send": "Send",
  "action.send_invitation_immediately": "Send invitation immediately",
  "action.send_request": "Send request",
  "action.share_with_link": "Share with link",
  "action.show_users": "Show users",
  "action.skip": "Skip",
  "action.step": "Step {{ current }} of {{ total }}",
  "action.try_again": "Try again",
  "action.update": "Update",
  "action.verify": "Verify",
  "action.view_profile": "View profile",
  "action.view_voucher": "View voucher",
  "action.yes_add": "Yes, add",
  "action.yes_apply": "Yes, apply",
  "action.yes_approve": "Yes, approve",
  "action.yes_cancel": "Yes, cancel",
  "action.yes_decline": "Yes, decline",
  "action.yes_delete": "Yes, delete",
  "action.yes_invite": "Yes, invite",
  "action.yes_mark_as_paid": "Yes, mark as paid",
  "action.yes_move": "Yes, move",
  "action.yes_publish": "Yes, publish",
  "action.yes_put_on_hold": "Yes, put on hold",
  "action.yes_reassign": "Yes, reassign",
  "action.yes_reject": "Yes, reject",
  "action.yes_remove": "Yes, remove",
  "action.yes_republish": "Yes, republish",
  "activate.administrate_team_info": "In the dashboard you can manage your team as leader.",
  "activate.download_app_info": "If you only want to follow the team, you can download the app or use our web app.",
  "activate.login_info": "If you are a manager and will manage your team, you must log in with a computer or iPad.",
  "activate.new_username": "This will be your username.",
  "activate.register_info": "To use Hoopit, you must register with your mobile number and password.",
  "activate.register_successful": "Thank you for signing up! \uD83C\uDF89",
  "activate.register_user": "Register user",
  "add_user.add_admin_confirmation": "Do you want to add <b>{{ name }}</b> as <b>{{ title }}</b> to <b>{{ group }}</b>?",
  "add_user.add_administrator": "Add administrator",
  "add_user.add_by_email": "Add by email",
  "add_user.add_by_name": "By name",
  "add_user.add_by_phone": "Add by phone",
  "add_user.add_user_confirmation": "Do you want to add <b>{{ name }}</b> to <b>{{ group }}</b>?",
  "add_user.back_to_search": "Back to search",
  "add_user.confirm_admin": "Confirm admin",
  "add_user.confirm_player": "Confirm player",
  "add_user.enter_email": "Enter email",
  "add_user.enter_name": "Enter name",
  "add_user.invite_manually": "Invite manually",
  "add_user.invite_new_admin": "Invite new administrator",
  "add_user.invite_new_player": "Invite new player",
  "add_user.new_user_form_description": "Invite person with phone number <b>{{ phoneCode }}{{ phoneNumber }}</b> as <b>{{ title }}</b>",
  "add_user.no_players_found": "We couldn't find players with this name",
  "add_user.no_players_found_description": "Please try another name or invite manually",
  "add_user.no_user_found_by_name": "No user with this name has been found",
  "add_user.no_user_found_by_name_description_1": "Please try another name or",
  "add_user.no_user_found_by_name_description_2": "add by phone number",
  "add_user.no_user_found_by_phone": "No user with this phone has been found",
  "add_user.no_user_found_by_phone_description": "Please try another number or press Next and invite person by this number",
  "add_user.register_admin": "Register admin",
  "add_user.register_player": "Register player",
  "add_user.registration_link": "Registration link",
  "add_user.search_admin_by_name_description": "Add admin by name or by phone number. Select \"Add by phone\" if they are not in the system yet.",
  "add_user.search_by_name": "Search by name",
  "add_user.search_in_organization": "Search in organization",
  "add_user.send_invitation": "Send invitation",
  "add_user.send_registration_link_to_guardians": "Send registration link to the guardians you want to add to <b>{{ group }}</b>.",
  "add_user.send_registration_link_to_players": "Send registration link to the players you want to add to <b>{{ group }}</b> or invite them manually.",
  "app_badges.google_play": "Available on Google Play",
  "app_badges.go_to_webapp": "Go to web app",
  "attendance_statistics.activities": "activities",
  "attendance_statistics.end": "End",
  "attendance_statistics.export_statistics": "Export attendance statistics",
  "attendance_statistics.no_activities": "No activities for selected period",
  "attendance_statistics.no_activities_selected": "No activities selected",
  "attendance_statistics.specify_period": "Specify period",
  "attendance_statistics.start": "Start",
  "bank_account.account_name": "Account name",
  "bank_account.account_number": "Account number",
  "bank_account.account_number_validation_message": "Account number must be 11 digits",
  "bank_account.add_new_account": "Add new account",
  "chat.add_members": "Add members",
  "chat.add_moderator_role": "Add moderator role",
  "chat.already_in_the_chat": "Already in the chat",
  "chat.chat_members": "Chat members",
  "chat.create_new_chat_with": "Create a new chat with",
  "chat.divider_today": "Today",
  "chat.divider_yesterday": "Yesterday",
  "chat.image": "Image",
  "chat.member": "Member",
  "chat.member_added_snackbar": "{{name}} is added to the chat",
  "chat.member_removed_snackbar": "{{name}} is removed from the chat",
  "chat.members": "Members",
  "chat.members_added_snackbar": "{{names}} are added to the chat",
  "chat.moderator": "Moderator",
  "chat.new_chat": "New chat",
  "chat.no_chats": "No chats here yet",
  "chat.not_available_for_chat": "Not available for chat",
  "chat.no_users_found": "No user by this name has been found",
  "chat.no_users_found_hint": "Please try another name",
  "chat.online": "online",
  "chat.owner": "Owner",
  "chat.remove_member": "Remove member",
  "chat.remove_member_description": "Are you sure you want to remove <b>{{ name }}</b> from the chat?",
  "chat.remove_moderator_role": "Remove moderator role",
  "chat.search_by_member_name": "Search by member name",
  "chat.selected_users": "Selected users",
  "chat.select_people_you_want_in_your_chat": "Select people you want in your chat",
  "chat.start_chat": "Start chat",
  "chat.start_conversations": "Start conversations via Hoopit app.",
  "chat.unpin": "Unpin",
  "chat.who_do_you_want_to_add": "Who do you want to add?",
  "chat.who_do_you_want_to_chat_with": "Who do you want to chat with?",
  "confirmation.add_child_to_group": "Do you want to add {{ childName }} to {{ groupName }}?",
  "confirmation.add_child_to_waiting_list": "The group is full, would you like {{ childName }} to join the waiting line?",
  "confirmation.checking_requirements": "Verifying Information",
  "confirmation.delete_user_payment": "Are you sure you want to delete this payment?",
  "confirmation.done": "Done",
  "confirmation.finished": "Finished",
  "confirmation.no": "No",
  "confirmation.save": "Save",
  "confirmation.save_and_notify": "Save and notify",
  "confirmation.sent": "Sent",
  "confirmation.skip_adding_child": "Do not add any children?",
  "confirmation.yes": "Yes",
  "dashboard.accounting_label": "Accounting",
  "dashboard.bank_accounts": "Bank accounts",
  "dashboard.calendar_label": "Calendar",
  "dashboard.communication_label": "Communication",
  "dashboard.conflicting_events": "Conflicting events",
  "dashboard.contact_support": "Contact support",
  "dashboard.dashboard": "Overview",
  "dashboard.dugnads": "Dugnads",
  "dashboard.economy_label": "Economy",
  "dashboard.events_label": "Events",
  "dashboard.expense_claims": "Expense claims",
  "dashboard.export_members": "Export members",
  "dashboard.fotballdata_label": "Football Data",
  "dashboard.group_settings_label": "Group settings",
  "dashboard.groups_label": "Groups",
  "dashboard.help_center": "Help center",
  "dashboard.help_label": "Help",
  "dashboard.individual_payments": "Payment requests",
  "dashboard.invoices": "Invoices",
  "dashboard.language": "Language",
  "dashboard.locations_label": "Locations",
  "dashboard.logout_label": "Log out",
  "dashboard.membership_dashboard": "Membership dashboard",
  "dashboard.membership_label": "Membership",
  "dashboard.my_profile": "My profile",
  "dashboard.new": "New",
  "dashboard.nif_connection": "NIF connection",
  "dashboard.nif_venues": "NIF venues",
  "dashboard.organization": "Organization",
  "dashboard.organization_label": "Organization",
  "dashboard.payment_provider": "Payment provider",
  "dashboard.payments": "Payments",
  "dashboard.payouts": "Payouts",
  "dashboard.productboard_label": "Product portal",
  "dashboard.sales_dugnads": "Sales dugnads",
  "dashboard.sms_configuration": "SMS configuration",
  "dashboard.stripe_not_connected_warning": "It seems like {{ organization }} is not integrated with Stripe.",
  "dashboard.switch_to_paid_mf_view": "Switch to paid MF view",
  "dashboard.team_account": "Team accounts",
  "dashboard.users_label": "Users",
  "dashboard.waiting_list_label": "Waiting list",
  "delete_member_requests_list_decline_description": "Users will not be added to the group, but you will be able to view declined requests in “Member requests”.",
  "denied.delete_paid_user_payment": "You cannot delete a paid payment",
  "denied.edit_paid_user_payment": "You cannot change a paid payment",
  "dugnad.payment_end_date": "Payment end date",
  "dugnad.payment_end_date_field_hint": "Players who join after this date will not be included in the dugnad.",
  "dugnad.payment_end_date_min_error": "Payment end date can't be earlier than publish date",
  "economy.above_age": "Above {{ age }}",
  "economy.account": "Account",
  "economy.account_deleted": "Bank account deleted.",
  "economy.account_length_hint": "Account number must be {{ number }} digits",
  "economy.add_1_product": "Add 1 product",
  "economy.add_age_group": "Add age group",
  "economy.add_age_restriction": "Add age restriction",
  "economy.add_at_least_one_manager": "Add at least 1 dugnad manager",
  "economy.add_at_least_one_product": "Add at least one product to continue",
  "economy.add_buy_out": "Add option to buy-out",
  "economy.add_buy_out_description": "Allow participants to buy themselves out of participation in the dugnad.",
  "economy.add_buy_out_price": "Add buy-out price",
  "economy.add_dugnad_manager": "Add dugnad manager",
  "economy.added": "Added",
  "economy.add_family_discount": "Add family discount",
  "economy.add_family_discount_description": "Set a price per family to make it possible for families to receive a discount. Set a minimum price per member to limit the discount.",
  "economy.add_family_discount_description_2": "Set a discount for family orders based on the number of family members.",
  "economy.add_group_restriction": "Add group restriction",
  "economy.add_n_products": "Add {{number}} products",
  "economy.add_price_category": "Add price category",
  "economy.add_product": "Add product",
  "economy.add_role_restriction": "Add role restriction",
  "economy.add_sibling_discount": "Add sibling discount",
  "economy.add_sibling_discount_description": "Set a discount based on the number of siblings, or set a max price per family by setting the discount to 100% after a number of siblings.",
  "economy.age_coverage_modal_text_1": "Seems like you haven’t specified a fee for ages:",
  "economy.age_coverage_modal_text_2": "Be sure that price categories cover all users.",
  "economy.age_coverage_modal_text_3": "Users that are not covered will not receive the membership fee.",
  "economy.age_coverage_modal_text_4": "Do you want to go back and fix it?",
  "economy.age_group": "Age group",
  "economy.age_groups": "Age groups",
  "economy.age_restriction": "Age restriction",
  "economy.age_restriction_description": "Players that are {{ age }} or older will not receive a discount and will not count towards the number of siblings in their family.",
  "economy.age_restriction_description_2": "Players reached this age will not receive a discount and will not count towards the number of siblings in their family.",
  "economy.age_restriction_description_3": "Players that are {{ age }} or older will not receive a discount and will not count towards the number of family members.",
  "economy.age_restriction_description_4": "Players reached this age will not receive a discount and will not count towards the number of family members.",
  "economy.all_ages": "All ages",
  "economy.all_fees": "All fees",
  "economy.allow_users_to_order_more_items": "Allow users to order more items",
  "economy.allow_users_to_order_more_items_description": "Participants will have the option to increase the number of items they wish to order.",
  "economy.apply_changes_to_sub_groups": "Apply changes to sub-groups",
  "economy.apply_changes_to_sub_groups_description": "When enabled, we will update the price for all sub-groups under this group.",
  "economy.at_least_n_items_in_total": "At least {{ number }} items in total",
  "economy.auto_buy_out_description": "Choose what players should do if they missed the confirmation due date: remain “not confirmed” (meaning that they are supposed to pick up items) or automatically set to “buy out”.",
  "economy.auto_buy_out_summary_1": "Players who missed the confirmation due date will remain “not confirmed”.",
  "economy.auto_buy_out_summary_2": "Players who missed the confirmation due date will be automatically set to “buy-out”.",
  "economy.bank_account": "Bank account",
  "economy.bank_account_account_number": "Account number",
  "economy.bank_account_account_status": "Account status",
  "economy.bank_account_account_status_description": "When account is enabled, you can choose it when creating new payments to collect money, or for reimbursing expense claims.",
  "economy.bank_account_add": "Add bank account",
  "economy.bank_account_additional_info_required_short": "Additional info required",
  "economy.bank_account_bank": "Bank",
  "economy.bank_account_bank_account_number": "Bank account number",
  "economy.bank_account_cannot_be_deleted": "Bank account cannot be deleted.",
  "economy.bank_account_cannot_create_tooltip": "You cannot create a bank account in {{ groupName }}. Please select a different group.",
  "economy.bank_account_cannot_deactivate": "Bank account cannot be deactivated because it’s the last account that is available for reimbursing expense claims and and the group associated with the account has submitted claims to it.",
  "economy.bank_account_cannot_delete_description": "Bank account cannot be deleted due to the following reason(s):",
  "economy.bank_account_cannot_delete_reason_1": "a payment was created using this account;",
  "economy.bank_account_cannot_delete_reason_2": "the account is used for an approved expense claim;",
  "economy.bank_account_cannot_delete_reason_3": "it’s the last account that is available for reimbursing expense claims and the group associated with the account has submitted claims to it;",
  "economy.bank_account_complete_setup": "Complete setup",
  "economy.bank_account_complete_setup_description_1": "Enter the full bank account number of {{ accountName }} from {{ bankName }}, the last 4 digits of which are \"{{ accountLast4 }}\".",
  "economy.bank_account_complete_setup_description_1.5": "Enter the full bank account number of {{ accountName }}, the last 4 digits of which are \"{{ accountLast4 }}\".",
  "economy.bank_account_complete_setup_description_2": "In case there are other existing bank accounts with the same account number, we will complete the setup for them automatically.",
  "economy.bank_account_complete_setup_validation_error": "Last 4 digits don’t match. Please try again.",
  "economy.bank_account_delete": "Delete bank account?",
  "economy.bank_account_delete_description": "A bank account can only be deleted if no payment has been made to it.",
  "economy.bank_account_description": "Payments paid after the change of bank account will be transferred to the new bank account. All already paid payments will be transferred to the current account.",
  "economy.bank_account_edit_name": "Edit account name",
  "economy.bank_account_edit_name_description": "Name of this bank account will be changed only for {{ groupName }}.",
  "economy.bank_account_empty_state": "No bank accounts have been added yet",
  "economy.bank_account_feedback_success": "Your request was registered. We will contact you soon.",
  "economy.bank_account_full_number_required": "Complete bank account setup",
  "economy.bank_account_full_number_required_description_1": "The system is being prepared to handle expenses and reimbursements of claims from members.",
  "economy.bank_account_full_number_required_description_2": "Please provide the full bank account number for the account connected to {{ groupName }}",
  "economy.bank_account_group": "Group",
  "economy.bank_account_group_type": "Group type",
  "economy.bank_account_information": "Account information",
  "economy.bank_account_name": "Account name",
  "economy.bank_account_name_was_updated": "Account name was updated.",
  "economy.bank_account_new": "New account",
  "economy.bank_account_no_accounts_found": "No accounts found",
  "economy.bank_account_no_accounts_found_description": "Try searching by another name",
  "economy.bank_account_number": "Bank account number",
  "economy.bank_account_number_validation_message": "Must be 11 digits",
  "economy.bank_account_search_by_account_name": "Search by account name",
  "economy.bank_account_status_hint": "If the account is disabled and there are existing payments connected to it, the money will continue to be deposited into this account, however, you will not be able to choose it for new payments.",
  "economy.bank_account_try_searching_by_another_name": "",
  "economy.bank_account_was_added_to_group": "New bank account was added to {{ groupName }}.",
  "economy.bank_account_was_added_to_group_and_updated": "New bank account was added to {{ groupName }} and updated for {{ amountOfGroups }} other groups.",
  "economy.bank_account_was_deleted": "The account was deleted.",
  "economy.bank_account_was_set_up_to_active": "The account was set up to active.",
  "economy.bank_account_was_set_up_to_inactive": "The account was set up to inactive.",
  "economy.bank_account_was_updated_for_group": "The account was updated for {{ groupName }}.",
  "economy.bank_account_was_updated_for_multiple_groups": "The account was updated for {{ groupName }} and {{ amountOfGroups }} other groups.",
  "economy.based_on_the_number_of_items_per_player": "based on the number of items per player",
  "economy.basic_price": "Basic price",
  "economy.bought_out": "Bought-out",
  "economy.bought_out_items": "Bought-out items",
  "economy.buy_out": "Buy-out",
  "economy.buy_out_net_amount_per_item": "Buy-out net amount per item",
  "economy.buy_out_option": "Buy out option",
  "economy.buy_out_option_description": "Calculation of the buy-out price is based on the number of items per player. If player is eligible for family discount, they can still choose to buy-out and get a discount.",
  "economy.buy_out_price": "Buy-out price",
  "economy.buy_out_price_per_item": "Buy-out price per item",
  "economy.by_account": "By account",
  "economy.by_confirmation_due_date": "By order due date",
  "economy.by_confirmation_status": "By confirmation status",
  "economy.by_due_date": "By due date",
  "economy.by_generation_date": "By generation date",
  "economy.by_group": "By group",
  "economy.by_last_reminder_date": "By last reminder date",
  "economy.by_net_amount": "By net amount",
  "economy.by_order_status": "By order status",
  "economy.by_payment_date": "By payment date",
  "economy.by_payment_due_date": "By payment due date",
  "economy.by_payment_status": "By payment status",
  "economy.by_payment_type": "By payment type",
  "economy.by_publish_date": "By publish date",
  "economy.cannot_delete_bank_account": "Bank account cannot be deleted, can only delete bank account that has not been paid into",
  "economy.change": "Change",
  "economy.cheapest_fee": "The cheapest fee",
  "economy.coming_soon": "Coming soon!",
  "economy.confirmation_due_date": "Order due date",
  "economy.confirmation_reminder": "Confirmation reminder",
  "economy.cost": "Cost",
  "economy.create_age_group": "Create age group",
  "economy.created_for": "Created for",
  "economy.created_for_1_group": "Created for 1 group",
  "economy.created_for_n_groups": "Created for {{ number }} groups",
  "economy.create_dugnad": "Create dugnad",
  "economy.create_dugnad_for": "Create dugnad for",
  "economy.create_membership_fee_description": "Create a payment for the membership fee. This payment will be obligatory for players, but is sent to everyone to encourage supporting memberships.",
  "economy.create_new_product": "Add custom product",
  "economy.create_order_for_1_group": "Create order for 1 group?",
  "economy.create_order_for_n_groups": "Create order for {{ number }} groups?",
  "economy.create_payment": "Create payment",
  "economy.create_payment_for_1_group": "Create payment for 1 groups",
  "economy.create_payment_for_n_groups": "Create payment for {{ number }} groups?",
  "economy.create_price_category": "Create price category",
  "economy.create_product": "Create product",
  "economy.create_survey_for_1_group": "Create survey for 1 group?",
  "economy.create_survey_for_n_groups": "Create survey for {{ number }} groups?",
  "economy.create_training_fee": "Create training fee",
  "economy.create_training_fee_description": "Create a payment for the annual training fee for a sport. Here you can set a price for each team individually and set up sibling discounts for families with multiple children in the club.",
  "economy.custom_pick_up_location": "Custom pick up location",
  "economy.declined": "Declined",
  "economy.delete_bank_account": "Delete {{ account_name }}",
  "economy.delete_bank_account_description": "A bank account can only be deleted if no payment has been made to it.",
  "economy.department_add_bank_account": "Add bank account in the division settings before creating fee.",
  "economy.department_add_team": "Add at least one team to the department before you create the training fee.",
  "economy.description": "Description",
  "economy.details_of_this_dugnad": "Details of this dugnad",
  "economy.different_delivery_warning": "Delivery times may vary",
  "economy.different_delivery_warning_description": "You've selected products from different suppliers. This could result in separate deliveries with varying arrival times.",
  "economy.discount": "Discount",
  "economy.discount_example_description": "The example is based on {{ items }} items per user with a price of NOK {{ price }} per item.",
  "economy.discount_example_family": "The Askeladden Felgen family",
  "economy.discount_for_other_siblings": "Discount for other siblings",
  "economy.discount_for_other_siblings_description": "This discount is given to all siblings after #{{ sibling }}. Set this to zero if you want them to pay full price.",
  "economy.discount_for_sibling_#": "Discount for sibling #{{ sibling }}",
  "economy.discount_level": "Discount stages: {{ level }}",
  "economy.discount_levels": "Discount levels",
  "economy.discount_value_100_warning_message": "Users with 100% discount will receive a payment of NOK 0.",
  "economy.discount_value_range_error_message": "The discount has to be between 0% and 100%.",
  "economy.division_permission_denied": "You are not allowed to create a training fee for this division",
  "economy.download_invoice": "Download invoice (PDF)",
  "economy.download_orders": "Download orders",
  "economy.dugnad": "Dugnad",
  "economy.dugnad_created": "Dugnad created",
  "economy.dugnad_no_access_success": "Your request was registered. We will contact you soon.",
  "economy.dugnad_no_access_text_1": "Do you want to request an early access?",
  "economy.dugnad_no_access_text_2": "Contact us! Pilots already started.",
  "economy.dugnad_no_access_textarea_placeholder": "Let us know why you are interested",
  "economy.dugnad_no_access_title": "Dugnads are coming soon!",
  "economy.dugnad_order_description": "All dugnad participants will be able to confirm their order until the confirmation due date.",
  "economy.dugnad_participants": "Dugnad participants",
  "economy.dugnad_payment": "Dugnad payment",
  "economy.dugnad_person_in_multiple_groups": "Note that person who plays in 2 or more groups will receive only 1 dugnad.",
  "economy.dugnad_reminder_description": "Reminder is sent as an email and a push notification in the app. The message contains the required dugnad information. Below you can add a personal message for <b>{{ name }}</b>.",
  "economy.dugnad_reminder_description_1": "Reminder is sent as an email and a push notification in the app. The message contains the following information: <i>select reminder type to see more details</i>.",
  "economy.dugnad_reminder_description_2": "Reminder is sent as an email and a push notification in the app. The message contains the following information: dugnad title, description and order due date.",
  "economy.dugnad_reminder_description_3": "Reminder is sent as an email and a push notification in the app. The message contains the following information: dugnad title.",
  "economy.dugnad_reminder_description_4": "Reminder is sent as an email and a push notification in the app. The message contains the following information: dugnad title, description and payment due date.",
  "economy.dugnad_reminder_description_hint": "Below you can add a personal message for <b>{{ name }}</b>.",
  "economy.dugnad_select_groups_description": "Select a group that will receive money from the dugnad.",
  "economy.dugnad_select_groups_description_2": "Select groups whose players will participate in the dugnad.",
  "economy.dugnad_settings_description": "Create a <b>survey</b> if you want to gather information about the number of items people would like to order from you. You will be able to create payment later.",
  "economy.dugnad_settings_description_2": "Or create a <b>payment</b> right away.",
  "economy.dugnad_update_success": "Dugnad for {{ name }} was updated",
  "economy.dugnad_was_updated": "Dugnad was updated",
  "economy.dugnad_will_be_created_for": "Dugnad will be created for",
  "economy.dugnad_without_orders": "Dugnad without orders",
  "economy.economy_empty_state_description": "Only <b>account managers</b> have access to this page",
  "economy.economy_empty_state_header": "Organization economy",
  "economy.edit_dugnad_for": "Edit dugnad for",
  "economy.edit_order_for": "Edit order for",
  "economy.edit_payment_due_date": "Edit payment due date",
  "economy.edit_payment_due_date_description": "Payment due date will be updated for all players, while payment publish date will only be updated for players who missed the confirmation due date ({{ date }}).",
  "economy.edit_payment_due_date_description_2": "For members that confirmed their orders, payments were published immediately.",
  "economy.edit_pick_up": "Edit pick up",
  "economy.edit_tf_description": "Changes will only apply to new members",
  "economy.edit_tf_description_2": "Existing payments will not be changed",
  "economy.enter_age_from_to": "Enter age from {{ ageFrom }} to {{ ageTo }}",
  "economy.estimated_income": "Estimated income",
  "economy.estimated_income_description_1": "Estimation doesn't include cases when a player chose an option to buy-out or received a family discount.",
  "economy.estimated_income_description_2": "Estimation doesn't include cases when a player chose an option to buy-out.",
  "economy.estimated_income_description_3": "Estimation doesn't include cases when a player received a family discount.",
  "economy.estimated_net_amount": "Estimated net amount",
  "economy.estimated_transaction_fee": "Estimated transaction fee",
  "economy.event_payment": "Event payment",
  "economy.event_with_payment": "Event with payment",
  "economy.example_based_on_price": "The example is based on a price of NOK {{ price }}.",
  "economy.example_description": "The example is based on a min # of items per user - {{ items }} and price per item of NOK {{ price }}.",
  "economy.example_for_adults": "Example: For adults",
  "economy.example_of_what_user_will_see": "Example of what user will see",
  "economy.excel_payment_description": "Generates an excel file for internal purposes. This file contains all payments per member, created in the selected year/month.",
  "economy.excel_payment_notification": "Available only for club and sport levels.",
  "economy.excel_payment_overview": "(Excel) Payment overview",
  "economy.exempted": "Exempted",
  "economy.exempted_items": "Exempted items",
  "economy.exempt_payment": "Exempt from payment",
  "economy.expected": "Expected",
  "economy.expected_income": "Expected income",
  "economy.family_discount": "Family discount",
  "economy.family_discount_description": "Please set the minimum price per member and family price.",
  "economy.family_discount_description_2": "Minimum price per member can’t be lower than 50 NOK because of NIF requirements.",
  "economy.family_discount_description_3": "The family price is the price that someone would pay for a number of family members if the total amount of paying their individual payments exceed what you set here.",
  "economy.family_discount_description_4": "If the individual amount in total for the whole family is lower than the \"family price\", the family will pay the original amount. Example: if the family price is NOK 650, the adult price is NOK 150 and children NOK 100, and there are two adults and two children, they will pay NOK 500.",
  "economy.family_discount_info": "Discount will be available for all family members who are players in the selected",
  "economy.family_discount_info_2": "Discount will only be applied when the user confirmes the order or payment.",
  "economy.family_discount_option_description": "Discount will be available for all family members who are players in the selected groups. Discount will only be applied when the user confirms the order or payment.",
  "economy.family_discounts_example": "Example of family discount",
  "economy.family_discounts_example_description": "Select people to pay for:",
  "economy.family_discounts_example_description_2": "Select family members now to receive a family discount",
  "economy.family_discounts_example_header": "Example of what a user will see",
  "economy.family_discounts_example_member_1": "The user's name",
  "economy.family_discounts_example_member_2": "Child 1",
  "economy.family_discounts_example_member_3": "Child 2",
  "economy.family_discounts_example_member_4": "The user's partner's name",
  "economy.family_discounts_example_optional_payment_hint": "Support your club by becoming an associate member",
  "economy.family_discounts_example_table_age": "Age",
  "economy.family_discounts_example_table_discount": "Discount",
  "economy.family_discounts_example_table_items": "Items",
  "economy.family_discounts_example_table_name": "Name",
  "economy.family_discounts_example_table_price": "Price",
  "economy.family_member_#": "Family member #",
  "economy.family_price": "Family price",
  "economy.family_price_note": "Family price includes transaction fee",
  "economy.family_price_validation": "Can't be lower than minimum price per member",
  "economy.fee_value_helper_text": "To avoid errors every field must be filled. Set the price to zero for groups that shouldn’t pay a training fee.",
  "economy.fill_at_least_one_age_field": "Fill at least one age field",
  "economy.find_goods": "Find goods",
  "economy.fixed_number": "Fixed number",
  "economy.fixed_#_of_items": "Fixed # of items",
  "economy.for_age": "For {{ age }}",
  "economy.forgot_anyone": "Forgot anyone?",
  "economy.for_group_type": "For",
  "economy.from_age_including": "From age (including)",
  "economy.from_age_to_age": "From {{ ageFrom }} to {{ ageTo }}",
  "economy.full_catalogue_button": "full catalogue",
  "economy.full_overview": "Full overview for",
  "economy.full_price": "Full price",
  "economy.generate_export": "Generate export",
  "economy.generate_invoice_for": "Generate invoice for",
  "economy.generate_new_invoice": "Generate new invoice",
  "economy.generation_date": "Generation date",
  "economy.give_your_dugnad_a_title": "Give your dugnad a title",
  "economy.go_back_and_fix": "Go back and fix",
  "economy.group": "Team",
  "economy.groups_have_no_accounts": "{{ groupName }} and its sub-groups have 0 bank accounts added",
  "economy.include_transaction_fee": "All marked prices include transaction fee",
  "economy.including_transaction_fee": "Including <span>{{ fee }}</span> transaction fee",
  "economy.income": "Income",
  "economy.invalid_range": "Invalid range",
  "economy.invoice": "invoice",
  "economy.invoice_history": "Invoice history",
  "economy.invoice_history_for": "Invoice history for",
  "economy.invoices": "invoices",
  "economy.invoices_i_created": "Invoices I generated",
  "economy.item": "item",
  "economy.items": "items",
  "economy.items_for_family_member": "Items for family member #{{ number }}",
  "economy.items_for_other_family_members": "Items for other family members",
  "economy.items_per_player": "Items per player",
  "economy.items_per_player_description": "Set the number of items for all players or customize it for different age groups.",
  "economy.items_per_player_description_2": "Minimum number of items allows players to increase the number of items to order in app. Fixed number locks this ability.",
  "economy.jump_to_member_payments": "Jump to member payments",
  "economy.latest_publish_date_description": "We will send out payments to everyone on the payment publish date.",
  "economy.latest_publish_date_description_2": "Participants can’t change the number of items if they miss the confirmation due date.",
  "economy.manager_is_already_on_the_list": "{{ name }} is already on the list",
  "economy.mandatory_for_new_players_joining": "Mandatory for new players joining",
  "economy.mandatory_tf_to_join_description": "When enabled, new players will be given 15 minutes to pay this training fee in order to confirm their membership in the group. Otherwise they will be automatically removed.",
  "economy.mandatory_tf_to_join_existing_payment_description_1": "This doesn’t affect a group with existing mandatory payments for joining.",
  "economy.mandatory_tf_to_join_existing_payment_description_2": "This doesn’t affect {{ number }} groups with existing mandatory payments for joining.",
  "economy.mandatory_tf_to_join_existing_payment_description_3": "You can change it by making adjustments for individual groups.",
  "economy.mandatory_tf_to_join_label": "Require new players to pay in order to join",
  "economy.mandatory_tf_to_join_label_2": "Applies to <b>{{ group }}</b> and all of its subgroups.",
  "economy.maximum_age_is": "Maximum age is {{ age }}",
  "economy.member_price": "Member price",
  "economy.members": "Members",
  "economy.membership_fee": "Membership fee",
  "economy.membership_fee_no_permission_description": "Only <b>organization-level admins</b> have access to this page",
  "economy.membership_fee_no_permission_header": "Organization economy",
  "economy.membership_payments": "Membership payments",
  "economy.members_under_age": "Applies to family members under {{ age }} years.",
  "economy.minimum_age_is": "Minimum age is {{ age }}",
  "economy.minimum_number_of_items": "Minimum number of items",
  "economy.minimum_#_of_items": "Minimum # of items",
  "economy.minimum_price_per_member": "Minimum price per member",
  "economy.minimum_price_per_member_validation": "Can't be lower than NOK 50",
  "economy.min_number_of_items": "Min number of items",
  "economy.most_expensive_fee": "The most expensive fee",
  "economy.multiple_price_categories": "Several price categories",
  "economy.multiple_price_categories_description": "Please set a custom price to generate an invoice",
  "economy.must_publish_for_show_progress": "Publish payment to see it’s progress",
  "economy.net_amount": "Net amount",
  "economy.net_amount_for_team": "Net amount for {{ team }}",
  "economy.net_amount_per_item": "Net amount per item",
  "economy.net_payout": "Net payout",
  "economy.new": "New",
  "economy.new_dugnad": "New dugnad",
  "economy.new_dugnad_payment": "New dugnad payment",
  "economy.new_invoice_description": "You will need to mark the payment as paid manually after receiving the payment confirmation of the invoice.",
  "economy.new_invoice_description_2": "We will not send automatic reminders to the user after the first invoice is generated. But you will still be able to send manual reminders, which will be sent as emails and push notifications in the app.",
  "economy.new_membership_fee": "New membership fee",
  "economy.new_payment": "New payment",
  "economy.new_pick_up_reminder_description": "Reminder is sent as an email and a push notification in the app. The automated message contains the required order information. Below you can add a personal message for <b>{{ firstName }} {{ lastName }}</b>.",
  "economy.new_pick_up_reminder_for": "New pick-up reminder for",
  "economy.new_reminder_description": "Reminder is sent as an email and a push notification in the app. The automated message contains the required payment information. Below you can add a personal message for <b>{{ firstName }} {{ lastName }}</b>.",
  "economy.new_reminder_for": "New reminder for",
  "economy.new_reminder_success": "New reminder was sent to {{ name }}",
  "economy.new_sales_dugnad": "New sales dugnad",
  "economy.new_training_fee": "New training fee",
  "economy.next": "Next",
  "economy.nif_minimum_price_description": "Minimum price per member should not be lower than 50 NOK because of NIF requirements.",
  "economy.no_bank_accounts": "No bank accounts found",
  "economy.no_bank_accounts_description": "The group in the group selector must have a bank account connected",
  "economy.no_divisions": "No divisions created yet",
  "economy.no_divisions_instruction": "Go to your groups and add at least one division in order to create a training fee.",
  "economy.no_order_created": "No order created",
  "economy.no_payments": "No previous payments",
  "economy.no_survey_created": "No survey created",
  "economy.no_teams_in_division": "This division does not have any teams",
  "economy.not_paid": "Not paid",
  "economy.not_paid_estimation_description": "Estimation based on all payments except declined, exempted and paid ones. The sum does not include transaction fee.",
  "economy.not_paid_in_total": "Not paid (in total)",
  "economy.not_paid_payments": "Not paid payments:",
  "economy.not_picked_up": "Not picked-up",
  "economy.number": "Quantity",
  "economy.number_of_items": "Number of items",
  "economy.number_of_items_per_person": "Number of items per person",
  "economy.number_of_items_per_player": "Number of items per player",
  "economy.#_of_items": "# of items",
  "economy.#_of_items_for_family_member": "# of items for family member #{{ number }}",
  "economy.#_of_items_for_other_family_members": "# of items for other family members",
  "economy._of_items_for_other_family_members_hint": "The # of items that all family members after #{{ number }} have to buy.",
  "economy.#_of_players": "# of players",
  "economy.one_fee": "Only one fee",
  "economy.ongoing": "Ongoing",
  "economy.optional": "Optional",
  "economy.order": "Order",
  "economy.order_created": "Order created",
  "economy.order_date_description": "The order will be published at 12:00.",
  "economy.order_publish_date": "Order publish date",
  "economy.order_reminder": "Order reminder",
  "economy.orders": "Orders",
  "economy.order_update_success": "Order for {{ name }} has been updated",
  "economy.other_options": "Other options",
  "economy.other_options_dugnad_description": "Every organization is different! Here you can adjust the dugnad to fit your needs.",
  "economy.other_options_mf_description": "Every club is different! Here you can adjust the membership fee to fit your needs.",
  "economy.other_options_tf_description": "Every club is different! Here you can adjust the training fee to fit your needs.",
  "economy.other_payment": "Other payment",
  "economy.other_payment_description": "Create a payment for the organization, a sport, a team or a person.",
  "economy.other_payments": "Other payments",
  "economy.other_payment_warning": "Add bank account in the group settings before creating the payment",
  "economy.outstanding": "Outstanding",
  "economy.overdue": "Overdue",
  "economy.paid": "Paid",
  "economy.paid_in_total": "Paid (in total)",
  "economy.paid_payments": "Paid payments",
  "economy.participants": "participants",
  "economy.past": "Past",
  "economy.past_payment": "Past payment",
  "economy.payment_date": "Payment date",
  "economy.payment_deadline": "Payment deadline",
  "economy.payment_deadline_validation_message_max": "?",
  "economy.payment_deadline_validation_message_min": "Deadline cannot be in past.",
  "economy.payment_details_price_categories": "Price categories",
  "economy.payment_details_tab_details": "Details",
  "economy.payment_details_tab_payments": "Payments",
  "economy.payment_export": "Payment export",
  "economy.payment_exports_label": "Payment export for ",
  "economy.payment_exports_label_2": " and sub-groups",
  "economy.payment_for": "Payment for",
  "economy.payment_for_description": "The payment is sent out at the 01.01.{{ year }}.",
  "economy.payment_for_description_2": "Players who join during the year receive the payment with a 14 day deadline.",
  "economy.payment_for_validation_message_max": "You probably didn't mean {{ years }} years from now? :)",
  "economy.payment_for_validation_message_min": "Fee cannot be created for past years.",
  "economy.payment_information": "Payment information",
  "economy.payment_is_mandatory_for_joining_the_group": "Payment is mandatory for joining the group",
  "economy.payment_is_mandatory_for_joining_the_group_2": "Mandatory payment wasn’t paid in time",
  "economy.payment_is_mandatory_for_joining_the_group_description": "{{ name }} has to pay this payment before payment deadline in order to confirm their membership in {{ group }}. Otherwise, they will be removed from the group.",
  "economy.payment_is_mandatory_for_joining_the_group_description_2": "{{ name }} didn’t pay this payment before the payment deadline, therefore they were automatically removed from the {{ group }}.",
  "economy.payment_is_mandatory_for_joining_the_group_description_3": "To provide the user with more time to pay, you can extend the payment deadline.",
  "economy.payment_is_mandatory_for_joining_the_group_description_4": "If you wish to keep the user in the group, you can change the payment status to \"Exempted\".",
  "economy.payment_is_mandatory_for_new_players_joining": "\"{{ payment }}\" is mandatory for new players joining these groups",
  "economy.payment_is_mandatory_for_new_players_joining_description": "New players and those who are automatically added from the groups’ waiting list will have to pay within 15 minutes and 24 hours respectively. If the payment is not made within the given time, such players will be removed from the group.",
  "economy.payment_note": "Comment",
  "economy.payment_note_invoice_hint": "The comment will be visible on the invoice",
  "economy.payment_paid": "Paid",
  "economy.payment_publish_action_description": "The payment will be published at 12:00",
  "economy.payment_publish_date_tooltip": "Players who missed the confirmation due date, receive payments on this day.",
  "economy.payment_reminder": "Payment reminder",
  "economy.payments": "Payments",
  "economy.payments_i_created": "Payments I created",
  "economy.payment_will_be_on_hold": "The payment will be put on hold and can be re-published at any time.",
  "economy.payout": "Payout",
  "economy.payouts_arrival_date_hint": "For payouts in progress, this is an estimate",
  "economy.pdf_payment_description": "Generates a formal PDF document for accounting purposes. This document contains the prove of all payments and fees for the selected payout date.",
  "economy.pdf_payment_report": "(PDF) Payment report",
  "economy.period": "Period",
  "economy.period_end": "Period end",
  "economy.period_invalid": "Invalid period",
  "economy.period_start": "Period start",
  "economy.picked_up": "Picked-up",
  "economy.pick_up": "Pick up",
  "economy.pick-up": "Pick-up",
  "economy.pick_up_description": "We will inform all {{ number }} dugnad participants with “not picked up” order status about the date and place where they can come to pick up the products.",
  "economy.pick_up_description_2": "{{ number }} dugnad participants with “not picked up” order status received information about the date and place where they can come to pick up the products.",
  "economy.pick_up_end": "Pick up end",
  "economy.pick_up_location": "Pick up location",
  "economy.pick_up_managers_description_1": "Select people who will be responsible for giving out the items and will have the rights to mark dugnad orders as picked up.",
  "economy.pick_up_managers_description_2": "To register picked up orders, they will need to enter the order code in app. This code will be provided by the person who picks up the items.",
  "economy.pick_up_manager_warning": "Items will not give themselves away. Please assign at least 1 person who will be responsible for it.",
  "economy.pick_up_reminder": "Pick-up reminder",
  "economy.pick_up_reminder_history_description": "Reminders are sent as emails and push notifications in the app. You can also send new reminder manually.",
  "economy.pick_up_start": "Pick up start",
  "economy.pick_up_time": "Pick up time",
  "economy.pick_up_time_passed": "Pick up time passed",
  "economy.pick_up_time_passed_description": "You can’t edit pick up after pick up time has passed.",
  "economy.pick_up_was_successfully_set": "Pick up was successfully set",
  "economy.pick_up_was_successfully_updated": "Pick up was successfully updated",
  "economy.player_fee_description": "Please select the rule to charge the training fee for members participating in several groups (all other training fees will be exempted):",
  "economy.player_fee_label": "Fee for players in several groups",
  "economy.player_fee_placeholder": "Fee that should be paid by player",
  "economy.previous_payment": "Last payment created for {{ year }}",
  "economy.price": "Price",
  "economy.price_categories_description_1": "Set price categories for {{ organization }} membership fee.",
  "economy.price_categories_description_2": "Be sure that price categories cover all users.",
  "economy.price_categories_description_3": "Users that are not covered will not receive the membership fee.",
  "economy.price_for_team": "Price for {{ team }}",
  "economy.price_option": "Price option",
  "economy.price_options": "Price options",
  "economy.price_per_item": "Price per item",
  "economy.price_per_item_description": "Please set the price per item of your product.",
  "economy.price_per_item_description_2": "You can also set the buy-out price in case some users do not want to participate in the dugnad.",
  "economy.price_per_player": "Price per player",
  "economy.price_range": "Price range",
  "economy.prices_for_teams": "Prices for each team in {{ division_name }}",
  "economy.prices_for_teams_description_1": "Members with several training fees will have to pay the cheapest one.",
  "economy.prices_for_teams_description_2": "Members with several training fees will have to pay the most expensive one.",
  "economy.prices_for_teams_description_3": "Members with several training fees will have to pay all fees.",
  "economy.prices_for_teams_required_to_join_description": "New players joining groups for which this payment was marked as mandatory, will be given 15 minutes to pay this training fee in order to confirm their membership. Otherwise, they will be automatically removed.",
  "economy.price_user_will_see_and_pay": "Price that user will see and pay",
  "economy.product_name_label": "Name",
  "economy.products": "Products",
  "economy.products_description": "Add the name of a product (for example, a pack of toilet paper) and its price per item.",
  "economy.products_description_1": "Looking for a trusted supplier and hassle-free delivery? Order from our partners, and we’ll take care of everything else!",
  "economy.products_description_2": "By adding multiple products, you'll allow players to choose how many of each they want to order to match the number of items per player set above.",
  "economy.profit": "Profit",
  "economy.publish_date_description": "The payment will be published at 12:00.",
  "economy.publish_date_mf_description": "The membership fee will be published at 12:00.",
  "economy.publish_date_tf_description": "The training fee will be published at 12:00.",
  "economy.publish_date_validation_message_min": "Publish date must be in the future.",
  "economy.received": "Received",
  "economy.remain_not_confirmed": "Remain \"not confirmed\"",
  "economy.reminder_explanatory_message": "A reminder will be sent out the day before the payment deadline, then a reminder will be sent 3, 5, 10 and 15 days after the payment deadline has expired. New players will also receive the training fee with 14 days due.",
  "economy.reminder_history_description_1": "Confirmation reminders are sent out automatically 2, 5 and 7 days before the confirmation due date and on the due date.",
  "economy.reminder_history_description_2": "Payment reminders are sent out automatically 2, 5 and 7 days after payment deadline has passed and then once a week.",
  "economy.reminder_history_description_3": "You can also send a pick-up reminder manually.",
  "economy.reminder_history_description_4": "All reminders are sent as emails and push notifications in the app.",
  "economy.reminder_text": "Reminder text",
  "economy.reminder_type": "Reminder type",
  "economy.remove_dugnad_manager": "Remove dugnad manager?",
  "economy.remove_dugnad_manager_description": "After saving changes, <b>{{ manager }}</b> will no longer participate in managing pick up for “{{ dugnad }}” dugnad. We will inform them via email when you save changes.",
  "economy.reports_unavailable": "Reports are not available yet",
  "economy.republish_order_description": "Confirmation due date ({{ date }}) is not yet passed. By changing confirmation status back to “not confirmed”, you will allow {{ name }} to make an order themself.",
  "economy.republish_order_for": "Republish order for {{ name }}",
  "economy.required": "Required",
  "economy.require_players_to_preorder_items": "Allow participants to pay later",
  "economy.require_players_to_preorder_items_description": "Participants can order items when the dugnad is published, and they will receive a payment request at a later date.",
  "economy.require_players_to_preorder_items_hint": "You can specify an order deadline, and when the payments will be sent. Participants who do not respond before the order deadline will be assigned the minimum amount of items. Players who join after the confirmation due date will not be included in the dugnad.",
  "economy.role": "Role",
  "economy.same_price_all_teams": "Should all teams pay the same price?",
  "economy.search_by_name_or_payment": "Search by name or payment",
  "economy.search_by_name_payment_invoice": "Search by name, payment or invoice number",
  "economy.search_by_payment_name": "Search by payment name",
  "economy.select_export_type": "Select export type",
  "economy.select_from_catalogue": "Add from catalogue",
  "economy.select_from_catalogue_description_1": "To simplify delivery, we recommend choosing products from just one supplier per dugnad campaign.",
  "economy.select_from_catalogue_description_2": "You can edit both the product name and price later if needed.",
  "economy.select_from_catalogue_description_3": "For detailed product information, view",
  "economy.select_period": "Select period",
  "economy.select_time_period": "Select time period",
  "economy.select_users": "Select users",
  "economy.select_users_label": "Choose who will receive the payment by clicking on \"Select users.\"",
  "economy.select_year": "Select year",
  "economy.set_age_groups": "Set age groups",
  "economy.set_pick_up": "Set pick up",
  "economy.set_price": "Set price",
  "economy.set_price_user_will_receive": "Set price that user will receive",
  "economy.set_to_buy_out": "Set to \"buy out\"",
  "economy.sibling_#": "Sibling no.",
  "economy.sibling_discount": "Sibling discount",
  "economy.sibling_discounts_example": "Example of sibling discount",
  "economy.specify_month": "Specify month",
  "economy.status_overview": "Detailed status overview",
  "economy.submit_categories": "Submit categories",
  "economy.suggested_price": "Suggested price",
  "economy.sum": "Sum",
  "economy.sum_does_not_include_transaction_fee": "The sum does not include transaction fee",
  "economy.summary": "Summary",
  "economy.survey": "Survey",
  "economy.survey_created": "Survey created",
  "economy.team": "team",
  "economy.teams": "teams",
  "economy.tell_players_what_this_dugnad_is_about": "Tell players what this dugnad is about",
  "economy.tf_adjust_for_individual_groups": "Adjust for individual groups",
  "economy.tf_default_net_amount": "Default net amount",
  "economy.tf_default_price": "Default price",
  "economy.tf_details_excluded_group_hint": "Members of this particular group did not receive this training fee. Sub-groups may still be included.",
  "economy.tf_exclude": "Exclude",
  "economy.tf_excluded_groups": "Excluded groups",
  "economy.tf_excluded_groups_description": "These groups were marked as excluded, therefore their players won’t have to pay this fee.",
  "economy.tf_mandatory": "Mandatory",
  "economy.tf_override_mandatory_payment": "Override mandatory payment",
  "economy.tf_override_mandatory_payment_description": "New players joining this group or its sub-groups are already required to pay other mandatory payment <b>{{ payment }}</b> to confirm their membership. Enabling this option will replace that payment with this training fee.",
  "economy.tf_summary_excluded_group_hint": "Members of this particular group will not receive this training fee. Sub-groups may still be included.",
  "economy.this_payment_is_mandatory_tooltip": "This payment is mandatory for joining the group.",
  "economy.title": "Title",
  "economy.to_age_including": "To age (including)",
  "economy.toilet_paper_pack": "Toilet paper pack",
  "economy.total": "Total",
  "economy.total_description_tooltip": "Totals are filtered by currently selected group and include all of its subgroups.",
  "economy.total_number_of_items": "Total number of items",
  "economy.total_price_including_fees": "Total price including transaction fee",
  "economy.total_price_to_buy_out": "Total price to buy-out",
  "economy.training_fee": "Training fee",
  "economy.training_fee_no_permission_description": "Only <b>economy managers</b> of this group or groups above have access to this page",
  "economy.training_fee_no_permission_header": "Division economy",
  "economy.training_payments": "Training payments",
  "economy.transaction_fee": "Transaction fee",
  "economy.transferred": "Transfered",
  "economy.under_age": "Under {{ age }}",
  "economy.upcoming": "Upcoming",
  "economy.update_age_group": "Update age group",
  "economy.update_price_category": "Update price category",
  "economy.update_product": "Update product",
  "economy.who_is_the_price_for": "Who's the price for?",
  "economy.will_be_published_description": "Payments are published individually after confirmation of the order or {{ publishUserPaymentsDate }} for people who missed confirmation due date ({{ confirmationDueDate }}).",
  "economy.will_be_published_on": "Will be published on",
  "economy.you_can_add_any_person_from": "You can add any person from",
  "event_details.account": "Account",
  "event_details.activity_for": "activity for ",
  "event_details.activity_type_description": "Create a single activity for a match, or larger arrangement, or a repeating activity for training or weekly arrangements.",
  "event_details.activity_type_description_2": " Single activity have more functionalities, like payment and response deadline.",
  "event_details.add_meetup": "Add meetup",
  "event_details.add_participants_limit": "Add participants' limit",
  "event_details.add_payment": "Add payment",
  "event_details.add_reminder": "Add reminder",
  "event_details.add_reminders": "Add reminders",
  "event_details.add_response_deadline": "Add response deadline",
  "event_details.all_activities": "All repetitive activity",
  "event_details.automatically_register": "Automatic register",
  "event_details.automatically_register_description": "All invited are automatically set to attend. We will remind them to decline if they cant attend!",
  "event_details.bi_weekly": "every other week",
  "event_details.cancel_activity": "Cancel activity",
  "event_details.cancel_choice": "What do you want to do?",
  "event_details.cancel_description": "Cancel the event if it’s no longer happening. Participants will be notified.",
  "event_details.cancelled_reason": "Cancellation reason",
  "event_details.cancelled_reason_placeholder": "Why is the activity canceled?",
  "event_details.cancel_payment_description": "NB There is no automatic refund for cancellation of paid activities.",
  "event_details.change": "Change",
  "event_details.change_activity_for_group": "Change activity for {{ groupName }}",
  "event_details.change_stop_repetition": "If you want to end the activity earlier, you can change the date of <strong>Stop Repeat</strong>",
  "event_details.choose_activity_type": "2. Choose activity type",
  "event_details.choose_a_group": "1. Choose a group",
  "event_details.choose_a_group_description": "Choose a group you want to create activity for.",
  "event_details.clear_for_this_occurrence": "Clear for this occurrence",
  "event_details.clear_location_or_reset_to_default": "Clear location or reset to default",
  "event_details.clear_location_or_reset_to_default_description": "Do you want to completely clear the location for this specific occurrence of {{ eventTitle }}, or reset it to the event's default location {{ location }}?\nDon’t forget to click “Save” when you are done editing. ",
  "event_details.contact_persons_required": "The event must have at least one and at most three contact persons.",
  "event_details.copy_event_button": "Duplicate",
  "event_details.custom_location": "Custom location",
  "event_details.deadline_subtext_2": "We will notify invited user 24 hour before the respond deadline provided.",
  "event_details.delete_activity": "Delete activity",
  "event_details.delete_description": "When you delete an event, it will be permanently gone. No one will be notified.",
  "event_details.delete_recurring": "Delete recurring event",
  "event_details.delete_recurring_all": "Past and future events will be deleted.",
  "event_details.delete_recurring_description": "Deleting all activities will delete all activities in the past and in the future.",
  "event_details.delete_recurring_future": "This and future events will be deleted.",
  "event_details.delete_recurring_single": "Past and future events will not be affected.",
  "event_details.delete_reminders": "Delete reminders",
  "event_details.description": "Description",
  "event_details.end": "End",
  "event_details.event_cannot_be_deleted_tooltip": "Events with payments can’t be deleted",
  "event_details.event_details": "Event details",
  "event_details.event_is_imported_from_excel": "Event is imported from Excel",
  "event_details.event_is_imported_from_fotballdata": "Event is imported from Fotballdata",
  "event_details.event_is_imported_from_nif": "Event is imported from NIF",
  "event_details.footballdata_button": "See more on NFF",
  "event_details.future_activities": "This and future",
  "event_details.invitations": "invitations",
  "event_details.last_manual_reminder": "Last manual reminder",
  "event_details.last_manual_reminder_counter": "({{x}} ago)",
  "event_details.limit": "Limit",
  "event_details.limit_members_invalid": "Set any number greater or equal 0",
  "event_details.location": "Location",
  "event_details.manual_register": "Manual register ",
  "event_details.manual_register_description": "All invited needs to respond to each occurrence. We will remind them!",
  "event_details.match": "Match",
  "event_details.match_description": "When enabled, event has a “match” indication visible on calendar and attendance statistics.",
  "event_details.meetup_location": "Meetup location",
  "event_details.meetup_location_subtext": "If you wish for another meetup location",
  "event_details.meetup_time": "Meetup time",
  "event_details.meetup_time_subtext": "Before event starts",
  "event_details.monthly": "each month",
  "event_details.new": "New",
  "event_details.no_posts_description": "Create posts to share updates, key information, or to simply stay connected.",
  "event_details.no_posts_title": "No posts",
  "event_details.only_this": "Only this",
  "event_details.participants": "participants",
  "event_details.participants_limit": "Players' limit",
  "event_details.participants_limit_helper_text": "When limit is reached, invitees can't accept invitation.",
  "event_details.participants_limit_hint": "The number of invitees who accepted the invitation exceeds the participants’ limit. You can manage responses by using Change responses option.",
  "event_details.payment": "Payment",
  "event_details.payment_future_warning": "Payment cannot be added after activity have started.",
  "event_details.payment_subtext": "Contact your Hoopit-admin in your club to add a bank-account",
  "event_details.payment_warning": "Payment cannot be changed after activity have started.",
  "event_details.price": "Price",
  "event_details.recurrence": "Recurrence",
  "event_details.recurring": "Recurring",
  "event_details.recurring_event": "This is a recurring event",
  "event_details.remind_unanswered_description": "We will send push notifications to the selected users and their guardians, reminding them to respond to the event invitation.",
  "event_details.remind_unanswered_title": "Send reminder to unanswered",
  "event_details.repeating_activity": "repeating",
  "event_details.reset_location": "Reset location",
  "event_details.save_and_notify_members_about_changes": "Save and notify members about changes?",
  "event_details.select_contact_persons": "Select contact person",
  "event_details.select_location": "Select location",
  "event_details.send_reminders": "Send reminders",
  "event_details.set_custom_location": "Set custom location",
  "event_details.single": "Single",
  "event_details.single_activity": "single",
  "event_details.start": "Start",
  "event_details.stop_recurrence": "Stop recurrence",
  "event_details.this_and_future_occurrences": "This and all future occurences",
  "event_details.this_is_a_recurring_activity": "This is a recurring activity",
  "event_details.this_is_a_recurring_event": "This is a recurring event",
  "event_details.this_is_match": "This is a match",
  "event_details.title": "Title",
  "event_details.update_only_this_or_all": "Do you want to update only this specific occurrence or this and all future occurrences? ",
  "event_details.update_stop_repetition_date": "Do you want to update the stop repetition date for {{ eventName }}?",
  "event_details.user_notified": "Participants will be notified.",
  "event_details.user_not_notified": "No one will be notified.",
  "event_details.weekly": "each week",
  "event_details.we_will_notify_members_about_cancellation": "We will notify invited members about cancellation.",
  "event_details.you_can_notify_members_about_changes": "You can notify members about changes.",
  "events.10_minutes": "10 minutes",
  "events.15_minutes": "15 minutes",
  "events.1_hour": "1 hour",
  "events.1_hour_30_minutes": "1 hour 30 minutes",
  "events.1_venue_with_conflicts": "{{ number }} venue with conflicts",
  "events.2_hours": "2 hours",
  "events.30_minutes": "30 minutes",
  "events.45_minutes": "45 minutes",
  "events.5_minutes": "5 minutes",
  "events.activity_cancelled": "Activity canceled",
  "events.add_attendance_note": "Add attendance note",
  "events.add_bank_account": "Contact admin in your organization to add bank account",
  "events.add_event": "Add event",
  "events.all": "Everyone",
  "events.all_venues": "All venues",
  "events.attendance": "Attendance",
  "events.attendance_locks_responses_description": "Invitees will not be able to change their responses after the attendance was registered.",
  "events.attendance_locks_responses_header": "Registering attendance will lock all responses",
  "events.attendance_registered": "Attendance was registered",
  "events.attendance_updated": "Attendance was updated",
  "events.attended": "Attended",
  "events.attending": "Attending",
  "events.automatic_registration": "Automatic registration",
  "events.auto_reg_on": "Option is not available for events with automatic registration",
  "events.before": "before",
  "events.before_start": "before start",
  "events.booked": "Booked",
  "events.book_venue": "Book venue",
  "events.calendar_is_not_public": "Calendar is not public",
  "events.calendar_sharing_permission_message": "The club's management must allow sharing of public calendar.",
  "events.cancel_event": "Cancel or Delete event",
  "events.cancel_event_button": "Cancel event",
  "events.cancel_event_description_1": "Cancel <b>{{event}}</b> if it’s no longer happening. You can reopen it later if needed.",
  "events.cancel_event_description_2": "We will notify the participants about cancellation.",
  "events.cancel_event_title": "Cancel event?",
  "events.change_attendance_description": "Current attendance of {{ userName }} is",
  "events.change_event": "Change event for {{ groupName }}",
  "events.change_occurrence": "Change 1 occurrence for {{ groupName }} ",
  "events.change_response": "Change response",
  "events.change_response_description": "Current response of {{ userName }} is",
  "events.change_response_description_2": "Please set the correct response.",
  "events.change_responses": "Change responses",
  "events.changes_apply_text": "Changes may not be applied right away",
  "events.change_to": "Change to {{ availableStatus }}",
  "events.change_venue": "Change venue",
  "events.checking_for_venue_conflicts": "Checking for venue conflicts",
  "events.conflicting_events_empty_state_description": "To see if there are any conflicting events on a venue, choose one in the dropdown above.",
  "events.conflicting_events_sub_header": "{{ number }} conflicting events at {{ venue }}",
  "events.create_event": "Create event",
  "events.create_event_description": "Create single events for matches or other one time activities and recurring events for trainings or other regular activities.",
  "events.create_events": "Create {{ createCount }} events ",
  "events.create_events_success": "{{ createCount }} events were successfully created.",
  "events.create_event_without_participants": "Create event without participants",
  "events.create_post_text": "Be the first! Create post using “New post” button.",
  "events.current_occurrence": "Current occurrence only",
  "events.days_before": "Days before",
  "events.days_in_advance": "Days before each event",
  "events.deadline": "Deadline",
  "events.declined": "Declined",
  "events.delete_event_button": "Delete event",
  "events.delete_event_confirmation": "Delete event?",
  "events.delete_event_confirmation_message": "<b>{{event}}</b> and all related data will be deleted permanently, and cannot be restored. Note that participants <em>will not</em> be notified.",
  "events.delete_event_confirmation_message_1": "This action cannot be undone.",
  "events.did_not_attend": "Did not attend",
  "events.edit_activity": "Change activity",
  "events.edit_attendance": "Edit attendance",
  "events.event": "Event",
  "events.event_cancelled": "Event “{{ eventName }}” has been cancelled",
  "events.event_cancelled_text": "Event cancelled",
  "events.event_contact_persons": "Contact person",
  "events.event_created": "Event “{{ eventName }}” has been successfully created",
  "events.event_creator": "Event creator",
  "events.event_deleted": "Event “{{ eventName }}” has been deleted",
  "events.event_end": "Event end",
  "events.event_information": "Event information",
  "events.event_moved_from_all_day": "Event “{{ eventName }}” has been successfully rescheduled and will be initially set to last for 1 hour",
  "events.event_moved_to_all_day": "Event “{{ eventName }}” has been successfully rescheduled and will be initially set to start at 00:00 and end at 23:59",
  "events.event_move_from_future_to_past_error": "You cannot move events to the past.",
  "events.event_name": "Event name",
  "events.event_start": "Event start",
  "events.event_started": "The event has started",
  "events.event_started_description": "You can not edit responses after the event has started.",
  "events.event_started_or_ended_error": "You cannot edit an occurrence that has already started or ended",
  "events.event_tags_list": "A list of tags selected for this event",
  "events.event_updated": "Event “{{ eventName }}” has been successfully updated",
  "events.event_without_participants": "Event without participants",
  "events.every": "Every",
  "events.every_month": "Repeat every month",
  "events.every_other": "Every other",
  "events.every_other_week": "Repeat every other week",
  "events.every_week": "Repeat every week",
  "events.excel_example_description": "Here you may see the example of Excel sheet with correct match information.",
  "events.excel_example_description_2": "Pay attention that all required columns, which are <b>Dato</b>, <b>Tid</b>, <b>Kampnr</b>, <b>Hjemmelag</b> and <b>Bortelag</b> should be filled to successfully create events.",
  "events.excel_example_header": "Example of Excel sheet",
  "events.excel_import": "Excel import",
  "events.external_event": "External event",
  "events.external_group": "External group",
  "events.for_group": "for {{ group }}?",
  "events.fotballdata": "Fotballdata",
  "events.fotballdata_description": "Keep your team’s calendar up-to-date by importing and syncing all matches with FIKS.",
  "events.going": "Going",
  "events.group_activities_description": "Here you see all the activities of the group you are a member of and all the subgroups that belong to it. If you're wondering how to create or change an activity, go inside",
  "events.groups": "Groups",
  "events.groups_in": "Groups in {{ organization_name }}",
  "events.here": "here",
  "events.hide_calendar": "Hide calendar",
  "events.hours_before": "Hours before",
  "events.illness": "Illness",
  "events.import": "Import",
  "events.imported_from": "Imported from",
  "events.import_events": "Import events",
  "events.import_events_confirmation_description": "You will be able to invite participants later from event details. All imported events will be updated dependant on <b>Kampnr</b>.",
  "events.import_events_description": "Import your Excel sheet and check the imported data afterwards. You will be able to edit events information and invite participants after creation.",
  "events.import_events_description2": "Import from <a href=\"http://handball.no/\" target=\"_blank\">handball.no</a><br/><a href=\"https://www.handball.no/system/sok/?search=&reg=all&content=klubb\" target=\"_blank\">Here</a> you can search for your team. Select the right team and then \"Terminliste\" to download an  Excel sheet with all matches.",
  "events.import_events_is_match": "Imported events will have a <b>“match”</b> indication visible on calendar and attendance statistics.",
  "events.import_excel": "Import Excel file",
  "events.import_matches": "Import matches",
  "events.import_matches_description": "Manually import matches from Excel sheet. All imported matches will be created as single events.",
  "events.injury": "Injury",
  "events.invitation_schedule": "Invitation schedule",
  "events.invite_current_occurrence": "Manage participants to current occurrence only or to this and all future occurrences?",
  "events.invited_description_text": "All invited are automatically set to attend. We will remind them to decline if they can't attend!",
  "events.invited_to_occurrence_text": "All invited needs to respond to each occurrence. We will remind them!",
  "events.invite_more": "Manage participants",
  "events.invite_participants": "Invite participants",
  "events.invite_to_recurring": "Invite only to this activity, or this and everyone in the repeating activity?",
  "events.location_section_name": "Section {{ sectionName }} ({{ numberOfTakenSections }}/{{ numberOfSiblingSections }} of the field)",
  "events.luxsave_sync_up_duration": "LuxSave sync-up may take up to 30 minutes",
  "events.manage_attendance": "Manage attendance",
  "events.manual_registration": "Manual registration",
  "events.many_days_in_advance": "{{ count }} days in advance",
  "events.match": "Match",
  "events.meet_up": "Meet-up",
  "events.minutes_before": "Minutes before",
  "events.more_options": "More options",
  "events.must_be_before_start": "Must be before start",
  "events.must_be_between_days": "Must be between 1 and 28 days",
  "events.must_be_between_hours": "Must be between 1 and 672 hours",
  "events.must_be_between_minutes": "Must be between 1 and 40,320 minutes",
  "events.must_be_between_weeks": "Must be between 1 and 4 weeks",
  "events.must_be_in_future": "Must be in future",
  "events.never_repeat": "Never",
  "events.new_activity": "New activity",
  "events.new_event": "New event",
  "events.new_event_description": "Please select the group this event will be created for. You can also change the type of event: use <b>single</b> for matches or other one time activities and <b>recurring</b> for trainings or other regular activities.",
  "events.new_event_description_2": "Don't forget to fill the title and description to help everyone understand what this event is about.",
  "events.no_activity": "No Activity for the selected period",
  "events.no_attendance_status": "No attendance status",
  "events.no_conflicting_events_found": "Nice! No conflicting events found",
  "events.no_locations_registered": "You have no locations registered.",
  "events.no_meetup_is_added": "No meet up is added",
  "events.noone_invited_text": "You have not invited anyone to the event “{{ eventName }}”. You can do it now or invite participants later from event details.",
  "events.no_payments": "No payments to show",
  "events.no_posts": "No posts added",
  "events.no_response_deadline_is_added": "No response deadline is added",
  "events.no_results": "No results",
  "events.not_answered": "Not answered",
  "events.not_going": "Not going",
  "events.nothing_found": "Nothing found",
  "events.not_registered": "not registered",
  "events.number_venues_with_conflicts": "{{ number }} venues with conflicts",
  "events.one_day_in_advance": "{{ count }} day in advance",
  "events.only_this": "Just this one",
  "events.organization_venues_description": "Here you will find an overview of all venues your organization has available. Here you will be able to see when it may be available in a venue and when it is not.",
  "events.other_groups": "Other groups",
  "events.other_reason": "Other reason",
  "events.other_training": "Other training",
  "events.participant_info": "Participant info",
  "events.participants": "Participants",
  "events.payment_cant_be_changed": "Payment cannot be changed after event has started.",
  "events.place": "Place",
  "events.posts_in_activity": "Posts related to the activity",
  "events.press_enter_to_set_custom_location": "Press Enter to set «{{ venueSearchValue }}» as a custom location",
  "events.reason": "Reason",
  "events.recurrence": "Repetition",
  "events.recurring_activity": "This is a repetitive activity",
  "events.register_attendance": "Register attendance",
  "events.reminder": "Reminder",
  "events.reminder_cannot_be_in_past": "Reminder cannot be in the past",
  "events.remove_from_event": "Remove from event",
  "events.remove_from_event_description": "{{ userName }} will be removed from event. You can re-invite them at any time.",
  "events.remove_from_event_question": "Are you sure you want to remove {{ first_name }} {{ last_name }} from event?",
  "events.remove_from_past_event": "Are you sure you want to remove {{ userName }} from event?",
  "events.reopen_event": "Reopen event?",
  "events.reopen_event_description": "Reopening <b>{{ event }}</b> will make it available for participation again. Note that participants <em>won't</em> be notified.",
  "events.response": "Response",
  "events.response_deadline": "Response deadline",
  "events.response_deadline_helper_text": "Participants can't change their response after deadline.",
  "events.response_updated": "Response was updated.",
  "events.roles": "Roles",
  "events.scheduled_invitation": "Add scheduled event invitation",
  "events.schedule_immediately": "Immediately",
  "events.schedule_immediately_description": "The event invitation will be sent immediately",
  "events.schedule_on_date": "On date",
  "events.schedule_on_date_description": "The event invitation will be sent on specific date which you can set up",
  "events.season_planner": "Season planner",
  "events.season_planner_description": "Ensure your team’s calendar stays updated and organized by leveraging automatic imports and syncing.",
  "events.section": "Section",
  "events.section_part": "Section",
  "events.sections_overlap_error": "Multiple events cannot occur on the same section at the same time",
  "events.sections_overlap_warning": "Multiple events occur on the same section at the same time",
  "events.see_paid_payments": "See paid payments",
  "events.see_payments": "See payments",
  "events.select_date": "Select date",
  "events.select_group_description": "Please select the group the events will be imported for. Remember that all imported matches will be created as single events.",
  "events.select_time": "Select time",
  "events.select_venue": "Select venue",
  "events.select_venues": "Select venues",
  "events.select_venue_to_load_events": "Select a venue to load events",
  "events.share_calendar": "Share calendar",
  "events.share_public_calendar": "Share public calendar",
  "events.share_specific_venues": "Share only specific venues",
  "events.sharing_calendar_description": "By sharing the calendar, people outside the sports team can get an overview of their activities.",
  "events.sharing_calendar_description_2": "You can also narrow down the list of venues on public calendar to specific ones by selecting them below.",
  "events.sharing_calendar_embed": "Embed",
  "events.sharing_calendar_embed_description": "Use iframe link to integrate venue calendar into your organization’s website.",
  "events.sharing_calendar_ical_ics": "iCAL/iCS",
  "events.sharing_calendar_ical_ics_description": "Use iCAL/iCS link to access calendar of the selected venue from other applications. This calendar is updated every 8-24 hours.",
  "events.sharing_calendar_public_link": "Public link",
  "events.sharing_calendar_public_link_description": "Use public link to allow people outside the sports team to get an overview of the activities.",
  "events.show_calendar": "Show calendar",
  "events.specify_reason": "Specify reason",
  "events.start": "Start",
  "events.subgroups": "Subgroups",
  "events.team": "Team",
  "events.this_future_occurrences": "This and all future occurrences",
  "events.th_of_every_month": "th of every month",
  "events.time": "Time",
  "events.toggle_color": "Color sort by venue.",
  "events.toggle_group": "Filter by group",
  "events.turnerings_admin": "NIFs TurneringAdmin",
  "events.turnerings_admin_description": "Easily import and sync your NIF events to Hoopit, saving you time and effort.",
  "events.update_events": "and update {{ updateCount }} events ",
  "events.update_events_success": "{{ updateCount }} events were successfully updated.",
  "events.updating": "Updating...",
  "events.user_was_removed": "{{ userName }} was removed from event.",
  "events.venue": "Venue",
  "events.venue_is_booked": "This venue is already booked",
  "events.venue_is_booked_description": "{{ location }} is already booked by another team for that time. Do you still want to book this venue?",
  "events.venue_is_booked_description_recurring": "{{ location }} is already booked by another team for this time period for {{ conflictsNumber }} out of {{ totalOccurrences }} occurences. Do you still want to book this venue?",
  "events.venues": "Venues",
  "events.waiting_list": "Waiting list",
  "events.weeks_before": "Weeks before",
  "events.with_football_data": "This event is imported from Footballdata",
  "events.your_groups": "Your groups",
  "expense_claims.account_number": "Number",
  "expense_claims.all": "All",
  "expense_claims.approve_claim_description_1": "Before approving, please review expenses for accuracy: receipts, costs, dates, categories, and descriptions.",
  "expense_claims.approve_claim_description_2": "Once approved, claim cannot be rejected or edited. We will generate a claim voucher and send it to the accounting system.",
  "expense_claims.approve_claim_description_3": "Select a bank account for reimbursement. Otherwise, the voucher won't indicate the source of funds for reimbursement.",
  "expense_claims.approve_claim_description_4": "However, due to the absence of a bank account for expenses in the selected group, the voucher won't indicate the source of funds for reimbursement.",
  "expense_claims.approve_claim_description_5": "Once approved, claim cannot be rejected or edited. We will generate a claim voucher and send it to the accounting system.",
  "expense_claims.approve_claim_header": "Approve claim?",
  "expense_claims.approved": "Approved",
  "expense_claims.approved_by": "Approved by",
  "expense_claims.cannot_approve_claims_description": "You cannot approve your own claims. Please contact economy managers of your club.",
  "expense_claims.category": "Category",
  "expense_claims.change_group_description_1": "Currently the claim is assigned to <b>{{ group }}</b>.",
  "expense_claims.change_group_description_2": "If you reassign it to a group that you don't have access to, the claim will no longer be visible to you.",
  "expense_claims.change_group_header": "Reassign claim to another group?",
  "expense_claims.change_group_success": "Claim was reassigned to {{ group }}",
  "expense_claims.change_project_header": "Change project?",
  "expense_claims.claim_approved_success": "Claim was approved. The voucher is being generated.",
  "expense_claims.claim_details": "Claim details",
  "expense_claims.claim_marked_as_paid_success": "Claim was marked as paid",
  "expense_claims.claim_name": "Claim name",
  "expense_claims.claim_overdue_warning": "Claim was submitted over 2 days ago",
  "expense_claims.claim_overdue_warning_description": "Please review and either approve or reject this claim.",
  "expense_claims.claim_rejected_success": "Claim was rejected",
  "expense_claims.details": "Details",
  "expense_claims.doesnt_support_expenses": "Doesn’t support expenses",
  "expense_claims.empty_state_description": "Only <b>economy managers</b> of this group or groups above have access to this page.",
  "expense_claims.empty_state_header": "Claims submitted to the club",
  "expense_claims.expense_description": "Description",
  "expense_claims.expense_description_hint_1": "Required for \"{{ category }}\" category",
  "expense_claims.expense_description_hint_2": "Optional",
  "expense_claims.expenses": "Expenses",
  "expense_claims.expense_updated_success": "Expense was updated",
  "expense_claims.group_doesnt_support_expenses": "Group doesn’t support expenses",
  "expense_claims.group_doesnt_support_expenses_description": "Please inform economy managers of the club that expenses are not enabled for this group.",
  "expense_claims.mark_as_paid": "Mark as paid",
  "expense_claims.mark_claim_as_paid_description_1": "By doing so you confirm that the reimbursement was paid to the indicated receiver.",
  "expense_claims.mark_claim_as_paid_description_2": "Claim creator will be notified.",
  "expense_claims.mark_claim_as_paid_header": "Mark claim as paid?",
  "expense_claims.marked_as_paid": "Marked as paid",
  "expense_claims.marked_as_paid_by": "Marked as paid by",
  "expense_claims.no_account_connected": "No account connected",
  "expense_claims.no_account_connected_description": "The group has no bank accounts available for reimbursing claims.",
  "expense_claims.note_from_receiver": "Note from claim creator",
  "expense_claims.no_voucher": "No voucher",
  "expense_claims.pay_from": "Pay from",
  "expense_claims.pay_to": "Pay to",
  "expense_claims.previously_rejected_by": "Previously rejected by",
  "expense_claims.project_is_not_added": "Project is not added",
  "expense_claims.project_updated_success": "Project was updated",
  "expense_claims.reason": "Reason",
  "expense_claims.receiver": "Receiver",
  "expense_claims.reject_claim_description_1": "In order to reject the claim, please provide a reason, so claim creator can understand issues and fix them.",
  "expense_claims.reject_claim_description_2": "You will not be able to see the rejected claim until it has been resubmitted.",
  "expense_claims.reject_claim_header": "Reject claim?",
  "expense_claims.rejected": "Rejected",
  "expense_claims.search_by_description": "Search by receiver, pay to or voucher id",
  "expense_claims.should_not_be_reimbursed": "Should not be reimbursed",
  "expense_claims.submitted": "Submitted",
  "expense_claims.submitted_by": "Submitted by",
  "expense_claims.the_voucher_is_being_generated": "The voucher is being generated",
  "expense_claims.update_expense_description_1": "Select expense category that is more suitable for this expense.",
  "expense_claims.update_expense_description_2": "Note that categories “Social”, “Travel” and “Other” require description.",
  "expense_claims.update_expense_header": "Update expense?",
  "football_data.add": "Add",
  "football_data.add_more_fix_id": "Add more fiksID here",
  "football_data.all": "All",
  "football_data.enter_path_to_register": "Enter the path you want to register and copy the FixID from the address bar",
  "football_data.find_team": "Find your team at",
  "football_data.fix_id": "fiksID",
  "football_data.fix_id_blank_error": "FixId cannot be blank",
  "football_data.fix_id_ellipsis": "FiksID ...",
  "football_data.football_data": "Football Data",
  "football_data.get_fix_id": "and get your fixID in the address bar.",
  "football_data.groups": "Groups",
  "football_data.hired": "(Leased)",
  "football_data.hoopit": "Hoopit",
  "football_data.import_campaign_data": "Import match data",
  "football_data.managed_by": "(managed by {{ group_name }}) *",
  "football_data.matched_data": "Matched data",
  "football_data.no_venues_registered": "You have no venues registered in Hoopit that can be connected to FotballData. Go to",
  "football_data.oops": "Ops...",
  "football_data.organization": "organization",
  "football_data.search_club": "Look up your club at fotball.no",
  "football_data.share": "Share",
  "football_data.to_add_venues": "to add sites.",
  "football_data.total_registered": "{{ total }} registered",
  "football_data.venue": "Venue",
  "football_data.venues": "Venues",
  "football_data.your_venues": "Your venues",
  "form.confirmation_due_date_min_error": "The date must be in the future and not earlier than order publish date",
  "form.description": "Description",
  "form.field_future_date": "Payment date must be in the past",
  "form.field_invalid_date": "Please enter a valid date",
  "form.field_required": "This field is required",
  "form.from": "From",
  "form.invalid_email": "Invalid email",
  "form.to": "To",
  "gender.female": "Female",
  "gender.male": "Male",
  "gender.unknown": "Unknown",
  "group.": "Group",
  "group.add_subgroup": "Add subgroup",
  "group.associate_members": "Associate members",
  "group.guardians": "Guardians",
  "group.include_subgroups": "Include subgroups",
  "group.leaders": "Leaders",
  "group.members": "Members",
  "group.name": "Name",
  "group.others": "Others",
  "group.parents": "Parents",
  "group.players": "Players",
  "group_settings.account_active": "Active",
  "group_settings.account_change_warning_message": "Only the CFO has access to change the account.",
  "group_settings.account_inactive": "Inactive",
  "group_settings.account_information": "Account Information",
  "group_settings.accounting_email": "Accounting email",
  "group_settings.accounting_email_description": "Add your accounting system's email inbox address, where we will send payment and refund vouchers, and club credit card documentation.",
  "group_settings.accounting_email_hint": "Generally, this should only be set for the organization group, and left empty for all other groups. But in case you need to send payment vouchers, refund vouchers and credit card documentation from this group only, to another accounting system, you can override the organization setting by filling in another email address here. Make sure this group’s bank account setting is also external to this organization, to avoid error with bank account reconciliation.",
  "group_settings.accounting_email_hint_1": "If no accounting email is specified for this group, we will automatically use the accounting email provided in the organization group ({{ default }}).",
  "group_settings.accounting_email_hint_2": "If no accounting email is specified for this group, we will automatically use the accounting email provided in the organization group (currently it’s not set there).",
  "group_settings.account_name": "Account name",
  "group_settings.account_number": "Account number",
  "group_settings.account_number_validation_message": "Account number must be 11 digits",
  "group_settings.account_status": "Account status",
  "group_settings.activate_waiting_list": "Activate waiting list",
  "group_settings.activate_waiting_list_description": "When enabled, once the players limit is reached, all players joining this group will be placed on the waiting list.",
  "group_settings.add_new_account": "Add new account",
  "group_settings.admins_can_approve_own_claims": "Admins can approve their own claims",
  "group_settings.admins_can_approve_own_claims_description": "When enabled, all economy managers can approve their own expense claims submitted to the group.",
  "group_settings.allow_requests_when_group_is_full": "Allow requests when group is full",
  "group_settings.allow_requests_when_group_is_full_description": "When enabled, new players will be able to request to join even after players limit is reached.",
  "group_settings.allow_requests_when_group_is_full_hint": "This option is available when a group has a limit for players.",
  "group_settings.apply_to_subgroups_title": "Apply to subgroups",
  "group_settings.cannot_delete_organization": "Organization can't be deleted",
  "group_settings.club_website": "The organization website",
  "group_settings.compatible_with_fotballdata": "Compatible with Fotballdata",
  "group_settings.contact_details": "Contact details",
  "group_settings.contact_details_description": "Keep the group easily accessible by providing relevant contact details.",
  "group_settings.contact_email": "Contact email",
  "group_settings.contact_phone": "Contact phone",
  "group_settings.custom_fields": "Custom fields",
  "group_settings.custom_member_fields": "Custom member fields",
  "group_settings.custom_member_fields_description": "Create custom fields (e.g. school, municipality or jersey size) to ensure that group members provide essential information.",
  "group_settings.delete_group": "Delete group",
  "group_settings.delete_group_description": "Are you sure you want to delete this group? Re-enabling it will require contacting support. All payments from deleted group can still be accessed in Economy.",
  "group_settings.delete_group_success": "Group was deleted.",
  "group_settings.delete_logo": "Delete logo",
  "group_settings.delete_logo_description": "Are you sure you want to delete the logo?",
  "group_settings.delete_member_requests_list": "Users waiting for approval",
  "group_settings.delete_member_requests_list_approve_description": "Users will be added to the group as players.",
  "group_settings.delete_member_requests_list_approve_title": "Approve ",
  "group_settings.delete_member_requests_list_decline_description": "Users will not be added to the group, but you will be able to view declined requests in “Member requests”.",
  "group_settings.delete_member_requests_list_decline_title": "Decline",
  "group_settings.delete_member_requests_list_description": "There is currently 1 user who requested to join <b>{{ group }}</b> as player. ",
  "group_settings.delete_member_requests_list_description_1": "There are currently {{ count }} users who requested to join <b>{{ group }}</b> as players. ",
  "group_settings.delete_waiting_list": "Users in the waiting list",
  "group_settings.delete_waiting_list_add_description": "Users will be added to the group as players.",
  "group_settings.delete_waiting_list_add_title": "Add to group",
  "group_settings.delete_waiting_list_clear_description": "Users will not be added to the group, and waiting list will be cleared.",
  "group_settings.delete_waiting_list_clear_title": "Clear waiting list",
  "group_settings.delete_waiting_list_description": "There is currently 1 user in the waiting list of <b>{{ group }}</b>. ",
  "group_settings.delete_waiting_list_description_1": "There are currently {{ count }} users in the waiting list of <b>{{ group }}</b>. ",
  "group_settings.description": "Description",
  "group_settings.economy": "Economy",
  "group_settings.external_group": "External group",
  "group_settings.external_group_description": "Users in external groups are not considered members of your organization, and are not required to pay membership fees. They will also not be synchronized to NIF, or count towards your active members.",
  "group_settings.general": "General",
  "group_settings.go_to_group_settings": "Go to group settings",
  "group_settings.group_info": "Group information",
  "group_settings.group_is_not_empty": "Group is not empty",
  "group_settings.group_is_not_empty_description": "Group can't be deleted if it contains members or sub-groups.",
  "group_settings.group_not_empty_conditions": "Your group still has ",
  "group_settings.group_visibility": "Group visibility",
  "group_settings.group_visibility_description": "Allow players and guardians to join this group via organization's registration page. Note that users who use the direct invitation link can still join.",
  "group_settings.has_member": "members.",
  "group_settings.has_sub_group": "sub-groups.",
  "group_settings.hidden_from_onboarding": "The group is hidden from everyone at",
  "group_settings.inactive_account_description": "When an account is set to inactive you will still receive any outstanding payments, but will not be able to select it when creating new payments.",
  "group_settings.invitation_link": "Invitation link",
  "group_settings.joining": "Joining",
  "group_settings.joining_description_1": "Select how new players can join this group. ",
  "group_settings.joining_description_2": "Note that it will not affect current group members, as well as manually added players.",
  "group_settings.limit_members": "Limit members number",
  "group_settings.limit_members_invalid": "Set any number greater or equal 0",
  "group_settings.logo": "Logo",
  "group_settings.logo_description": "Upload a logo for this group. It may be displayed in registration, emails, invoices, etc.",
  "group_settings.main_details": "Main details",
  "group_settings.main_details_description": "Keep the group’s details accurate and aligned with its purpose.",
  "group_settings.make_this_group_visible": "Make this group visible for all users",
  "group_settings.make_this_group_visible_description": "When enabled, the group is visible to everyone at the organization's registration page.",
  "group_settings.mandatory_payment": "Mandatory payment",
  "group_settings.mandatory_payment_description_1": "Set up a mandatory payment to control who joins this group.",
  "group_settings.mandatory_payment_description_2": "New players joining the group directly will be given 15 minutes to pay. Those who are automatically added from the waiting list or approved from member requests will have 24 hours to pay.",
  "group_settings.mandatory_payment_description_3": "Note that it will not affect current group members, as well as manually added players.",
  "group_settings.mandatory_payment_description_4": "If the fee is unpublished, on hold, or if its period has not started or has ended, players won’t be able to join.",
  "group_settings.mandatory_payment_description_5": "If the payment is not made within the given time, such players will be removed from the group. ",
  "group_settings.mandatory_payment_hint": "There are no training fees available for this group",
  "group_settings.mandatory_payment_warning": "New players can’t join this group",
  "group_settings.mandatory_payment_warning_1": "The training fee is not published, preventing us from sending payments to new players who wish to join. To resolve this, please select other training fee or publish this training fee.",
  "group_settings.mandatory_payment_warning_2": "The period of this training fee has ended, preventing us from sending payments to new players who wish to join. To resolve this, please select a training fee with a valid period.",
  "group_settings.manual_approval": "Manual approval",
  "group_settings.manual_approval_description": "New players will be placed in the member requests list (“Memberships” > “Member requests”) and need to be manually approved to become members.",
  "group_settings.mark_this_group_as_external": "Mark this group as external",
  "group_settings.mark_this_group_as_external_description": "When enabled, members of this group and its subgroups will not be considered members of your organization, will not be required to pay membership fees and will not be synchronized with NIF.",
  "group_settings.member_and_subgroup": "members and sub-groups.",
  "group_settings.members_limit": "Members limit",
  "group_settings.move_users_to_member_requests_list": "Users will require manual approval",
  "group_settings.move_users_to_member_requests_list_description": "By applying these changes, users who are currently in the waiting list of <b>{{ group }}</b> will be moved to “Member requests”, where they will wait for approval to join.",
  "group_settings.move_users_to_member_requests_list_description_1": "By applying these changes, all users requesting to join <b>{{ group }}</b> will be added to “Member requests”, where they will wait for approval to join.",
  "group_settings.move_users_to_waiting_list": "Users will be moved to waiting list ",
  "group_settings.move_users_to_waiting_list_description": "By applying these changes, {{ count }} users who requested to join <b>{{ group }}</b> as players will be moved from “Member requests” to “Waiting list”. From there, once free spots become available, they will be automatically added to the group in the same order they applied.",
  "group_settings.move_users_to_waiting_list_x_success": "{{ count }} users are being moved, this may take some time.  ",
  "group_settings.name": "Name",
  "group_settings.no_accounts_message": "No account associated with the group.",
  "group_settings.no_accounts_warning_message": "Only the account manager can add an account.",
  "group_settings.open": "Open",
  "group_settings.open_description_1": "New players can join the group immediately after signing up, as long as the player limit is not reached. Otherwise, no one will be able to join.",
  "group_settings.organization_number": "Organization number",
  "group_settings.payout_period": "Payout period",
  "group_settings.payout_period_description": "Set the time period for transferring funds to group’s bank accounts.",
  "group_settings.permission_to_approve_own_claims": "Permission to approve own expense claims",
  "group_settings.permission_to_approve_own_claims_description": "Allow admins of this group to approve their own expense claims made towards this group.",
  "group_settings.players_limit": "Players limit",
  "group_settings.players_limit_description": "Set a limit for the number of players who can join this group.",
  "group_settings.players_limit_exceeded_warning_description": "Adding these users will exceed the maximum player limit ({{ limit }} players).",
  "group_settings.players_limit_exceeded_warning_title": "Players limit will be exceeded",
  "group_settings.please_remove_members": "Please delete everyone in the group and try again.",
  "group_settings.please_remove_subgroups": "Please delete subgroups and try again.",
  "group_settings.redirecting_to": "Redirecting to",
  "group_settings.registration_page": "registration page",
  "group_settings.registration_page_note": "the organization's registration page",
  "group_settings.request_more_fields": "Request more fields",
  "group_settings.send_payment_as_mandatory_description": "When enabled, added users must pay this fee within 24 hours to remain in the group. Otherwise, it will be sent as a regular payment.",
  "group_settings.send_payment_as_mandatory_title": "Send “{{ payment }}” as mandatory payment",
  "group_settings.settings": "settings",
  "group_settings.sign_up": "Signup",
  "group_settings.stripe": "Stripe",
  "group_settings.stripe_information": "Stripe Information",
  "group_settings.this_group_type_does_not_support_signup": "This group type does not support signup.",
  "group_settings.update_stripe_information": "Update Stripe Information",
  "group_settings.visible_from_onboarding": "The group is visible to everyone at",
  "group_settings.waiting_list": "Waiting list",
  "group_settings.waiting_list_description": "Allow players to join the waiting line for an available spot in this group.",
  "group_settings.waiting_list_description_1": "Once the players limit is reached, new players will be placed in the waiting list (“Groups” > “Waiting list”) and will be automatically added to the group in the same order they signed up. ",
  "group_settings.waiting_list_description_2": "Once the players limit is reached, new players will be placed in this list and will be automatically added to the group in the same order they signed up. ",
  "group_settings.waiting_list_enable_description": "After reaching players limit, all users joining this group will end up in the waiting list",
  "group_settings.website": "Website",
  "group.type": "Group type",
  "group_type.associate_members": "Associate members",
  "group_type.communication_group": "Communication group",
  "group_type.division": "Division",
  "group_type.organization": "Organization",
  "group_type.other": "Other",
  "group_type.team": "Team",
  "img_alt.empty_mailbox": "Empty mailbox",
  "img_alt.hoopit_logo": "Hoopit logo",
  "individual_payments.all_payments": "All",
  "individual_payments.ongoing_payments": "Ongoing payments",
  "individual_payments.past_payments": "Past payments",
  "invite_user.add_users_to_payment": "Add users to payment",
  "invite_user.create_event": "Create event",
  "invite_user.create_payment": "Create payment",
  "invite_user.empty": "No {{ tab_name }}",
  "invite_user.group_activity_invite": "added to the group in the future will be invited to this activity.",
  "invite_user.invitation_description": "Everyone you choose will be sent an invitation and will notify you of future changes.",
  "invite_user.invitation_description_2": "Everyone you choose will receive the payment in their app.",
  "invite_user.invitations": "Invitations",
  "invite_user.invitation_sent": "Invitation sent",
  "invite_user.invitations_tab": "invitations",
  "invite_user.invite_future": "Invite future {{ tab_name }}",
  "invite_user.invite_to_activity": "Here you can invite others in the sports team to the activity",
  "invite_user.number": "Number of",
  "invite_user.search": "Search",
  "invite_user.search_by_name": "Search by name",
  "invite_user.search_result": "Found {{ x }}",
  "invite_user.send_invitations": "Send invitations",
  "invite_user.send_payments": "Send payments",
  "invite_user.users_invited_this_may_take_some_time": "Users are being invited, it may take some time",
  "languages.en": "English",
  "languages.nb": "Norwegian",
  "languages.uk": "Ukrainian",
  "locations.add_default_behavior_tag": "Add default behavior tag for",
  "locations.add_default_tag": "Add default tag",
  "locations.add_location": "Add location",
  "locations.add_locations_text": "Please add locations on the",
  "locations.add_locations_text_2": "page in order to integrate with LuxSave.",
  "locations.add_more_tags": "Add more tags",
  "locations.add_tag": "Add tag for",
  "locations.add_tag_description": "Add a description for this tag, so the event creators will understand what it does.",
  "locations.cannot_be_changed": "Cannot be changed afterwards",
  "locations.confirm_default_tag": "Confirm default tag",
  "locations.connect_location": "Connect {{ locationName }} to LuxSave",
  "locations.connect_location_link": "LuxSave Integration Website",
  "locations.connect_location_text": "In order to connect this location to LuxSave, enter this ID into the “Register of a new calendar” form at",
  "locations.create_first_tag_text": "Please create a first tag for a default behavior of LuxSave. After this you will be able to add regular tags.",
  "locations.custom_name": "Custom name",
  "locations.default_behavior": "Default behavior",
  "locations.default_behavior_keyword": "The default behavior does not have a keyword in LuxSave.",
  "locations.default_behavior_text": "This is the default behavior",
  "locations.delete_location": "Delete location",
  "locations.delete_location_confirmation": "Are you sure you want to delete this location? Events from this location will not be deleted.",
  "locations.delete_selected_tag": "Delete {{ tagName }} tag",
  "locations.delete_tag": "Delete tag",
  "locations.delete_tag_message": "Are you sure you want to delete this tag?",
  "locations.edit": "Edit {{ locationName }}",
  "locations.edit_default_tag": "Edit default behavior tag for",
  "locations.edit_location": "Edit location",
  "locations.edit_tag": "Edit tag",
  "locations.edit_tag_for": "Edit tag for",
  "locations.field": "Field",
  "locations.finish": "Finish",
  "locations.go_to_location_list": "Go to location list",
  "locations.how_many_sections": "How many sections (maximum number of playfields) does this location support? This <b>cannot</b> be changed afterwards.",
  "locations.information_luxsave": "Information for LuxSave connection",
  "locations.integrations": "Integrations",
  "locations.last_syncronized": "Last syncronized",
  "locations.locaiton_list_text": "List of locations that have been integrated with LuxSave.",
  "locations.locaiton_list_text_2": "It can take up to 30 minutes to synchronize updates.",
  "locations.location_list": "Location list",
  "locations.location_set_up": "Location was successfully set up",
  "locations.location_type": "Location type",
  "locations.luxsave": "LuxSave",
  "locations.luxsave_keyword": "LuxSave keyword",
  "locations.luxsave_keyword_text": "Enter the LuxSave keyword. Please make sure that it matches the LuxSave keyword. Otherwise, it will not work properly.",
  "locations.luxsave_tags": "LuxSave tags",
  "locations.name": "Name",
  "locations.never": "never",
  "locations.no_locations": "No locations have been added yet",
  "locations.no_locations_added": "No locations have been added yet",
  "locations.no_locations_available": "No locations available for LuxSave integration",
  "locations.other": "Other",
  "locations.owner": "Owner",
  "locations.page_access_text": "Only <b>field coordinators</b> have access to this page",
  "locations.sections": "Sections",
  "locations.select_location": "Select location to connect with LuxSave",
  "locations.set_tag_name": "Please set a name for the tag. It will be shown to event creators.",
  "locations.set_tags": "Set tags for",
  "locations.settings": "{{ locationName }} settings",
  "locations.shared": "shared",
  "locations.sync_up_duration_text": "Sync-up may take up to 30 minutes",
  "locations.tag_description_text": "This tag will be used to represent the default behavior (no keyword) in LuxSave. This will make it easier for others to understand what the default behavior does.",
  "locations.tags": "Tags",
  "member_list.action_add": "The certificate with issue date {{ date }} was added",
  "member_list.action_extend": "The certificate with issue date {{ issueDate }} was extended by {{ extendedByYears }} years (till {{ endDate }})",
  "member_list.action_history": "History of actions",
  "member_list.action_history_empty": "No certificate history",
  "member_list.action_history_for": "History of certificate actions for {{ name }}",
  "member_list.action_remove": "The certificate with issue date {{ date }} was removed",
  "member_list.action_update": "The certificate was updated: issue date {{ issueDate }}, end date {{ endDate }}, verification date {{ verificationDate }}",
  "member_list.add_certificate": "Add certificate",
  "member_list.add_certificate_confirmation": "I confirm that I have seen the certificate and I confirm its validity",
  "member_list.add_certificate_description": "Please add certificate start and end dates",
  "member_list.add_certificate_description_2": "Please set the verification date for the certificate",
  "member_list.add_certificate_for": "Add certificate for {{ name }}",
  "member_list.add_certificate_success_toast": "Certificate was successfully added",
  "member_list.certificate": "Certificate",
  "member_list.certificate_details": "Certificate details",
  "member_list.certificate_expired": "Certificate expired",
  "member_list.certificate_info": "Certificate info",
  "member_list.certificate_is_valid": "Certificate is valid",
  "member_list.certificate_required": "Certificate required",
  "member_list.certificates": "Certificates",
  "member_list.criminal_record_certificates": "Criminal record certificates",
  "member_list.criminal_record_certificates_no_access": "Only <strong>Criminal record managers</strong> have access to this page",
  "member_list.does_not_have_certificate": "Does not have certificate",
  "member_list.does_not_require_certificate": "Does not require certificate",
  "member_list.empty_sub_group": " has no sub groups",
  "member_list.empty_sub_group_info": "Press the button below to create a subgroup in",
  "member_list.end_date": "End date",
  "member_list.end_date_validation_message": "Date must be in the future and after issue date",
  "member_list.expires": "Expires",
  "member_list.expires_in_1_day": "Expires in 1 day",
  "member_list.expires_in_days": "Expires in {{ number }} days",
  "member_list.expires_soon": "Expires soon",
  "member_list.expires_today": "Expires today",
  "member_list.extend_certificate": "Extend certificate",
  "member_list.extend_certificate_confirmation": "I confirm that I want to extend the certificate.",
  "member_list.extend_certificate_description": "Are you sure you want to extend certificate of {{ name }} for {{ number }} years?",
  "member_list.extend_certificate_description_2": "The end date will be changed from {{ endDateBefore }} to {{ endDateAfter }}.",
  "member_list.extend_certificate_success_toast": "Certificate was successfully extended",
  "member_list.extended_certificate": "Extended certificate",
  "member_list.extend_for_years": "Extend for {{ number }} years",
  "member_list.filter": "Filter",
  "member_list.has_been_extended": "Has been extended",
  "member_list.has_certificate": "Has certificate",
  "member_list.has_valid_certificate": "Has valid certificate",
  "member_list.in": "in",
  "member_list.invite_more_people": "You can invite more people to {{ organization_name }} with the link in the group's settings",
  "member_list.issue_date": "Issue date",
  "member_list.issue_date_validation_message": "Date must be before end date and not in the future",
  "member_list.members": "Members",
  "member_list.members_certificate_expires_soon": "{{ number }} members' certificate will expire soon",
  "member_list.members_missing_certificate": "{{ number }} members are missing certificates",
  "member_list.members_require_certificate": "{{ number }} members are required to have a certificate",
  "member_list.member_was_exempted": "Member was exempted",
  "member_list.mf_not_published": "Membership fee has not been published",
  "member_list.min_2_characters": "Minimum 2 characters",
  "member_list.missing_certificate": "Missing certificate",
  "member_list.navigate_to_members": "View members",
  "member_list.no_certificate_required": "No certificate required",
  "member_list.no_members_found": "We found no members",
  "member_list.nothing_found": "We found none",
  "member_list.overview": "Overview",
  "member_list.remove_certificate": "Remove certificate",
  "member_list.remove_certificate_confirmation": "I confirm that I want to remove the certificate irrevocably",
  "member_list.remove_certificate_description": "Are you sure you want to remove the certificate of {{ name }} that is valid to {{ date }}?",
  "member_list.remove_certificate_success_toast": "Certificate was successfully removed",
  "member_list.requests": "Requests",
  "member_list.requirement_settings": "Requirement settings",
  "member_list.requirement_settings_for": "Requirement settings for {{ name }}",
  "member_list.requires_certificate": "Requires certificate",
  "member_list.search": "Search",
  "member_list.search_by_name": "Search by name",
  "member_list.tf_not_published": "Training fee has not been published",
  "member_list.update_certificate": "Update certificate",
  "member_list.valid_from": "Valid from",
  "member_list.verification_date": "Verification date",
  "member_list.verification_date_validation_message": "Date must be between issue date and end date and not in the future",
  "member_list.verified": "Verified",
  "member_list.view_members_info": "Press \"members\" in the menu above to see personal info.",
  "member_list.with_number": "with mobile number",
  "member_requests.decline_x_success": "{{ count }} requests are being declined, this may take some time.",
  "membership.add_note_for": "Add note for",
  "membership.age": "Age",
  "membership.age_gender_statistics": "Age-gender statistics",
  "membership.age_gender_statistics_hint": "This widget only includes members with their gender set in Hoopit. In order to see complete statistics, make sure all {{ membersType }} have set genders in their profiles.",
  "membership.all_time": "All time",
  "membership.any_ideas": "Any ideas or suggestions for this page? Please share!",
  "membership.awaiting_confirmation": "Awaiting confirmation",
  "membership.back_to_overview": "Back to overview",
  "membership.by_joined_date": "By join date",
  "membership.by_last_nif_confirmation_reminder": "By last NIF confirmation reminder",
  "membership.cancel_membership": "Cancel membership",
  "membership.cancel_membership_bulk": "Cancel membership for {{ usersCount }} members?",
  "membership.cancel_membership_description_1": "You are about to cancel the membership for <b>{{ firstName }} {{ lastName }}</b> in <b>{{ group }}</b>.",
  "membership.cancel_membership_description_2": "They will be removed from the following groups in Hoopit:",
  "membership.cancel_membership_description_3": "The following NIF memberships will be cancelled:",
  "membership.cancel_membership_description_bulk": "You are about to cancel the membership for {{ usersCount }} people in <b>{{ group }}</b>:",
  "membership.cancel_membership_description_exempt": "You will exempt the selected ones:",
  "membership.cancel_membership_description_unpaid_count": "<b>{{ firstName }} {{ lastName }}</b> has <b>{{ paymentsCount }}</b> not paid payments.",
  "membership.cancel_membership_success": "Membership of {{ name }} is cancelling, it may take some time.",
  "membership.cancel_membership_success_2": "Membership of {{ name }} was cancelled",
  "membership.cancel_membership_success_bulk": "Memberships of {{ usersCount }} people are cancelling, it may take some time.",
  "membership.cancel_membership_success_exempting": "{{ paymentsCount }} payments are exempting, this may take some time",
  "membership.confirmation_in_progress": "Confirmation in progress",
  "membership.confirmation_sent": "A new confirmation request has been sent to {{ name }}.",
  "membership.confirmation_sent_bulk": "A new confirmation request has been sent to {{ usersCount }} members.",
  "membership.confirmed": "Confirmed",
  "membership.confirmed_description": "This is the list of confirmed members in <b>{{ group }}</b>.",
  "membership.confirm_membership": "Confirm membership",
  "membership.confirm_membership_success": "{{ name }} has joined {{ group }} without Hoopit account and was moved to Confirmed. ",
  "membership.confirm_without_hoopit": "Confirm without Hoopit",
  "membership.confirm_without_hoopit_description_1": "You are about to confirm <b>{{ firstName }} {{ lastName }}</b> as a club member without a Hoopit account. They will appear in the list of club members, but will not have access to Hoopit.",
  "membership.confirm_without_hoopit_description_2": "This should only be used for members that are not expected to use Hoopit in the future, such as veteran members etc.",
  "membership.details": "Details",
  "membership.edit_note_for": "Edit note for",
  "membership.emailed_on": "Emailed on {{date}}",
  "membership.email_mismatch_warning_description_1": "NIF has sent a membership confirmation request for {{user}} to <b>{{email}}</b>, which doesn’t match our records and may be outdated or incorrect.",
  "membership.email_mismatch_warning_description_2": "Please reach out to the user to confirm the correct email. If needed, they can update it in MinIdrett or contact NIF Support for assistance.",
  "membership.email_mismatch_warning_title": "Warning: emails mismatch",
  "membership.enroll_in_nif": "Enroll in NIF",
  "membership.enroll_in_nif_description_1": "An email from NIF will be sent to <b>{{user}}</b> asking them to confirm their membership.",
  "membership.enroll_in_nif_description_2": "Once confirmed, their status in Hoopit will be automatically updated.",
  "membership.expires_in": "expires in",
  "membership.export_statistics": "Export statistics",
  "membership.external_users": "External users",
  "membership.hoopit_account": "Hoopit account",
  "membership.imported": "Imported",
  "membership.imported_description_1": "This is a list of members imported from NIF, that are not found in Hoopit.",
  "membership.imported_description_2": "These members are not confirmed yet, and you can do one of the following:",
  "membership.imported_description_2_list_item_1": "invite to Hoopit (they will be moved to “Pending” once they confirm the invitation)",
  "membership.imported_description_2_list_item_2": "cancel membership",
  "membership.imported_description_2_list_item_3": "confirm membership (the member will not have access to Hoopit, requires NIF validation)",
  "membership.imported_description_3": "All data and memberships are provided directly by NIF.",
  "membership.imported_from_nif": "Imported from NIF",
  "membership.invitation_sent": "Invitation sent",
  "membership.invite_to_hoopit": "Invite to Hoopit",
  "membership.invite_to_hoopit_bulk": "Invite {{ usersCount }} members to Hoopit",
  "membership.invite_to_hoopit_description_1": "You are about to send <b>{{ firstName }} {{ lastName }}</b> an invitation to join Hoopit and <b>{{ group }}</b>. If they accept the invitation, they will become a member.",
  "membership.invite_to_hoopit_description_2": "If they do not accept the invitation, the contact information may be outdated, or they no longer wish to be a member.",
  "membership.invite_to_hoopit_description_bulk": "You are about to send {{ usersCount }} people an invitation to join Hoopit and <b>{{ group }}</b>. If they accept the invitation, they will become a member.",
  "membership.joined_date": "Joined date",
  "membership.member_joined": "Member joined",
  "membership.member_left": "Member left",
  "membership.member_requests": "Member requests",
  "membership.member_requests_1_user_waiting": "1 user waiting for ",
  "membership.member_requests_all": "All",
  "membership.member_requests_all_description": "{{ count }} requests in total",
  "membership.member_requests_approve": "Approve ",
  "membership.member_requests_approved": "Approved",
  "membership.member_requests_approved_description": "{{ count }} approved requests",
  "membership.member_requests_approve_single_description": "<b>{{ name }}</b> will be added to <b>{{ group }}</b> as a {{ role }}.",
  "membership.member_requests_approve_single_title": "Approve request?",
  "membership.member_requests_approve_x_description": "{{ count }} requests will be approved.",
  "membership.member_requests_approve_x_list_of_users": "Users to be approved for membership in <b>{{ group }}</b>:",
  "membership.member_requests_approve_x_title": "Approve {{ count }} requests?",
  "membership.member_requests_decline": "Decline",
  "membership.member_requests_declined": "Deсlined",
  "membership.member_requests_declined_description": "{{ count }} declined requests",
  "membership.member_requests_decline_single_description": "<b>{{ name }}</b>‘s request to join <b>{{ group }}</b> as a {{ role }} will be declined.",
  "membership.member_requests_decline_single_success": "{{ name }}’s request was declined.",
  "membership.member_requests_decline_single_title": "Decline request?",
  "membership.member_requests_decline_x_description": "{{ count }} requests will be declined.",
  "membership.member_requests_decline_x_list_of_users": "Users to be declined for membership in <b>{{ group }}</b>: ",
  "membership.member_requests_decline_x_title": "Decline {{ count }} requests?",
  "membership.member_requests_disabled_button": "Go to settings",
  "membership.member_requests_disabled_description": "If you want to manually approve each player who joins {{ group }}, select “Manual approval” in group settings.",
  "membership.member_requests_disabled_title": "Member requests not activated",
  "membership.member_requests_message_to_user": "Message to user",
  "membership.member_requests_message_to_user_approve": "We're very glad to have you as part of our team.",
  "membership.member_requests_message_to_user_decline": "Unfortunately, your request has been declined this time. We appreciate your understanding.",
  "membership.member_requests_note_add": "Add note for {{ user }}",
  "membership.member_requests_note_edit": "Edit note for {{ user }}",
  "membership.member_requests_note_hint": "Visible to admins only",
  "membership.member_requests_pending": "Pending",
  "membership.member_requests_pending_description": "{{ count }} pending requests",
  "membership.member_requests_players_limit_exceeded_warning_description": "Adding these users will exceed the maximum player limit in {{ group }}.",
  "membership.member_requests_players_limit_exceeded_warning_description_1": "Adding this user will exceed the maximum player limit in {{ group }}.",
  "membership.member_requests_send_payment_as_mandatory_description": "When enabled, users added to {{ group }} must pay a mandatory fee within 24 hours to stay in the group. Otherwise, it will be sent as a regular payment.",
  "membership.member_requests_send_payment_as_mandatory_title": "Send mandatory payment",
  "membership.member_requests_x_users_waiting": "{{ count }} users waiting for ",
  "membership.members": "Members",
  "membership.members_active_and_supervised_filter": "Active and Supervised",
  "membership.members_all_users": "All users",
  "membership.members_description": "with {{ group }} membership.",
  "membership.members_hoopit_status_filter": "Hoopit status",
  "membership.members_joined": "Members joined",
  "membership.members_joined_table_hint": "This table contains only members who joined {{ group }} in the selected timeframe and are still members of it or any of its sub-groups",
  "membership.members_joined_tooltip": "Displays data from NIF if available, otherwise displays data from Hoopit",
  "membership.members_left": "Members left",
  "membership.members_left_table_hint": "This table contains only members who left {{ group }} (and all of its sub-groups if any) in the selected timeframe",
  "membership.members_missing_email_filter": "Missing email (for both user and guardians)",
  "membership.members_movement": "Overall change",
  "membership.members_not_published_mf_tooltip": "Membership fee is not published",
  "membership.members_paid_mf_external_tooltip": "External users don’t receive membership fees",
  "membership.members_requires_attention": "Requires attention",
  "membership.members_statistics": "Members statistics",
  "membership.members_users_counter": "{{count}} users",
  "membership.missing_email": "Missing email",
  "membership.missing_membership_fee": "Missing membership fee",
  "membership.missing_payment": "Missing payment",
  "membership.new_reminder_description": "Reminder is sent as an email and a push notification in the app. The automated message contains the required payment information. Below you can add a personal message for <b>{{ fullName }}</b>.",
  "membership.new_reminder_description_for_x": "Reminder is sent as an email and a push notification in the app. The automated message contains the required payment information. Below you can add a personal message for <b>{{ count }}</b> members.",
  "membership.new_reminder_for": "New reminder for",
  "membership.new_reminder_for_x": "New reminder for {{ count }} members",
  "membership.nif_email_expiration_warning_description": "The confirmation link expires after 24 hours, but you can resend it anytime.",
  "membership.nif_email_expiration_warning_title": "NIF email expires after 24 hours",
  "membership.nif_membership": "NIF membership",
  "membership.no_email_provided": "No email provided",
  "membership.no_hoopit_account": "No Hoopit account",
  "membership.no_nif_membership": "No NIF membership",
  "membership.no_notes_added": "No notes added",
  "membership.not_connected": "Not connected",
  "membership.not_connected_tooltip": "The user is not connected to NIF, but may have an existing account. They can register or connect to NIF in our app, or via email.",
  "membership.not_enrolled": "Not enrolled",
  "membership.not_enrolled_cancelled": "cancelled",
  "membership.not_enrolled_cancelled_tooltip": "The user is connected to NIF, but their club membership is currently cancelled in NIF. ",
  "membership.not_enrolled_tooltip": "The user is connected to NIF, but does not have a club membership.",
  "membership.page_under_construction": "This page is under construction",
  "membership.paid_mf_period": "Paid in {{year}}",
  "membership.paid_mf_statistics_title": "Members paid for membership fee",
  "membership.pending": "Pending",
  "membership.pending_confirmation": "Pending confirmation",
  "membership.pending_confirmation_tooltip": "The user is connected to NIF, but their membership is not yet confirmed.\nNIF has sent an email with a link to a confirmation form that must be completed before it expires.",
  "membership.pending_description_1": "This is the list of people using Hoopit, that are in the process of becoming a member of <b>{{ group }}</b>.",
  "membership.pending_description_2": "Here, you can see which step of the process they are at and take action to support them through the process.",
  "membership.pending_description_3": "When all requirements are fulfilled they will become a confirmed member.",
  "membership.recently_joined": "Recently joined",
  "membership.reminder_history": "Reminder history",
  "membership.reminder_history_description": "Reminders are sent as emails and push notifications in the app. The message is based on the presence or expiration date of the certificate.",
  "membership.request": "request",
  "membership.request_expired": "Request expired ({{date}})",
  "membership.request_nif_connection": "Request NIF connection",
  "membership.request_nif_connection_bulk_description_1": "We will try to send an email and a push notification with a link to register or connect to an existing NIF account to {{x}} users and their guardians.",
  "membership.request_nif_connection_bulk_description_2": "{{count}} out of {{totalCount}} users you selected will receive an email and push notification with a link to register or connect to an existing NIF account.",
  "membership.request_nif_connection_description": "Note that all members can also connect to NIF directly through our app at any time.",
  "membership.request_nif_connection_single_description_1": "We will try to send an email and a push notification with a link to register or connect to an existing NIF account to <b>{{user}}</b> and their guardians.",
  "membership.request_nif_connection_single_description_2": "Link will be sent to {{emails}}.",
  "membership.requests": "requests",
  "membership.requires_approval": "Requires approval",
  "membership.requires_confirmation": "Requires confirmation",
  "membership.search_by_name_phone_email_or_relative": "Search by name, phone, email or relative",
  "membership.select_date_placeholder": "Select year",
  "membership.send_certificate_reminders": "Send certificate reminders",
  "membership.send_confirmation_reminder": "Send form for submission in NIF",
  "membership.send_invitation": "Send invitation",
  "membership.send_invitation_succes_bulk": "An invitation to join Hoopit was sent to {{ usersCount }} people.",
  "membership.send_invitation_success": "An invitation to join Hoopit was sent to {{ name }}.",
  "membership.send_message": "Send email",
  "membership.send_message_success": "Your message has been sent.",
  "membership.send_message_to": "Send email to",
  "membership.send_new_reminder": "Send new reminder",
  "membership.sent_to": "Sent to",
  "membership.set_specific_date": "Set specific date",
  "membership.still_members": "Still members",
  "membership.suggestion": "Suggestion",
  "membership.suggestion_received": "We received your suggestion, thank you!",
  "membership.this_month": "This month",
  "membership.this_week": "This week",
  "membership.this_year": "This year",
  "membership.total_joins": "Total joins",
  "membership.total_members": "Members",
  "membership.total_paid_mf_statistic": "Total users paid MF",
  "membership.unknown_age": "Unknown",
  "membership.user": "User",
  "membership.users": "Users",
  "membership.verified_by_nif_description": "User information has been verified by NIF against the Norwegian National Population Register",
  "membership.who_to_invite": "Who do you want to invite?",
  "membership.who_to_invite_description": "User will be invited to <b>{{ group }}</b>. Use group selector to change the group.",
  "membership.your_message": "Your message",
  "miscellaneous.1_day_ago": "1 day ago",
  "miscellaneous.1_hour_ago": "1 hour ago",
  "miscellaneous.1_like": "1 like",
  "miscellaneous.1_minute_ago": "1 minute ago",
  "miscellaneous.1_week_ago": "1 week ago",
  "miscellaneous.1_year": "1 year",
  "miscellaneous.all": "All",
  "miscellaneous.and": "and",
  "miscellaneous.and_sub_groups": "and sub-groups",
  "miscellaneous.april": "April",
  "miscellaneous.august": "August",
  "miscellaneous.beta": "Beta",
  "miscellaneous.change_to": "Change to",
  "miscellaneous.d": "d",
  "miscellaneous.december": "December",
  "miscellaneous.deselect_all": "Deselect all",
  "miscellaneous.division": "division",
  "miscellaneous.divisions": "divisions",
  "miscellaneous.dont_show_message_again": "Don’t show this message again.",
  "miscellaneous.february": "February",
  "miscellaneous.filter": "Filter",
  "miscellaneous.full_description": "Full description",
  "miscellaneous.group": "group",
  "miscellaneous.groups": "groups",
  "miscellaneous.h": "h",
  "miscellaneous.help_us_improve_page_description": "Do you have improvement ideas or feel like something is missing? Contact us!",
  "miscellaneous.help_us_improve_page_title": "Help us improve this page",
  "miscellaneous.in": "in",
  "miscellaneous.include_transaction_fee": "Include transaction fee",
  "miscellaneous.in_total": "In total",
  "miscellaneous.invalid_format": "Invalid format",
  "miscellaneous.january": "January",
  "miscellaneous.july": "July",
  "miscellaneous.june": "June",
  "miscellaneous.just_now": "just now",
  "miscellaneous.last_month": "Previous month",
  "miscellaneous.last_week": "Previous week",
  "miscellaneous.last_year": "Previous year",
  "miscellaneous.learn_more": "Learn more",
  "miscellaneous.like": "Like",
  "miscellaneous.likes": "Likes",
  "miscellaneous.m": "m",
  "miscellaneous.march": "March",
  "miscellaneous.max_number_characters": "Max {{ number }} characters",
  "miscellaneous.may": "May",
  "miscellaneous.message": "Message",
  "miscellaneous.min": "min",
  "miscellaneous.min_2_characters": "Minimum 2 characters",
  "miscellaneous.minimum": "Minimum",
  "miscellaneous.more": "More",
  "miscellaneous.n_days_ago": "{{ number }} days ago",
  "miscellaneous.n_days_ago_short": "{{n}}d ago",
  "miscellaneous.never": "Never",
  "miscellaneous.n_hours_ago": "{{n}} hours ago",
  "miscellaneous.n_hours_ago_short": "{{n}}h ago",
  "miscellaneous.n_likes": "{{ number }} likes",
  "miscellaneous.n_minutes_ago": "{{n}} minutes ago",
  "miscellaneous.n_minutes_ago_short": "{{n}}m ago",
  "miscellaneous.none": "None",
  "miscellaneous.no_sub_groups_found": "No sub-groups found",
  "miscellaneous.note": "Note",
  "miscellaneous.nothing_found": "Nothing found",
  "miscellaneous.november": "November",
  "miscellaneous.n_weeks_ago": "{{n}} weeks ago",
  "miscellaneous.n_weeks_ago_short": "{{n}}w ago",
  "miscellaneous.n_years": "{{ number }} years",
  "miscellaneous.october": "October",
  "miscellaneous.of": "of",
  "miscellaneous.oops_something_went_wrong": "Oops, something went wrong",
  "miscellaneous.or": "or",
  "miscellaneous.please_try_again": "Please try again.",
  "miscellaneous.please_try_another_name_or_phone_number": "Please try another name or phone number",
  "miscellaneous.redirecting": "Redirecting...",
  "miscellaneous.search": "Search",
  "miscellaneous.search_by_name": "Search by name",
  "miscellaneous.select_all": "Select all",
  "miscellaneous.selected_group": "Selected group",
  "miscellaneous.september": "September",
  "miscellaneous.set_specific_period": "Set specific period",
  "miscellaneous.share_feedback": "Share feedback",
  "miscellaneous.share_your_suggestion": "Share your suggestions",
  "miscellaneous.show_all": "Show all",
  "miscellaneous.status": "Status",
  "miscellaneous.sub_groups": "Sub-groups",
  "miscellaneous.this_month": "This month",
  "miscellaneous.this_week": "This week",
  "miscellaneous.this_year": "This year",
  "miscellaneous.today": "Today",
  "miscellaneous.total": "Total",
  "miscellaneous.total_net_amount": "Total net amount",
  "miscellaneous.w": "w",
  "miscellaneous.we_couldnt_find_anyone": "We couldn't find anyone",
  "miscellaneous.year": "year",
  "miscellaneous.years": "years",
  "new_group.add": "Add group",
  "new_group.add_in_group": "Add a new group below",
  "new_group.communication_group_info": "You don't need to create your own groups for coaches, team leaders and parent contacts. We create these automatically when someone is assigned one of these roles.",
  "nif.active": "Active",
  "nif.back_to_club_selection": "Back to Club Selection",
  "nif.cancel_club_membership": "Cancel club membership",
  "nif.cancel_club_membership_description": "{{ first_name }} {{ last_name }} will also be removed from {{ group }} and all sub-groups in Hoopit.",
  "nif.cancel_nif_club_membership": "Cancel NIF club membership",
  "nif.cancel_nif_sport_membership": "Cancel NIF sport membership",
  "nif.cancel_sport_membership": "Cancel sport membership",
  "nif.cancel_sport_membership_description": "{{ first_name }} {{ last_name }} will also be removed from {{ group }} and all sub-groups in Hoopit.",
  "nif.confirm_club_description": "Please confirm which organizations and groups you are a member of. You will receive invoices for membership fees and training fees for any groups you remain a member of.",
  "nif.confirm_club_membership": "Confirm Club Membership",
  "nif.delete_from_nif": "Delete from NIF",
  "nif.delete_from_nif_and_hoopit": "Delete from NIF and Hoopit",
  "nif.delete_from_nif_only": "Only delete from NIF",
  "nif.delete_options": "You can remove {{ name }} from Hoopit in {{organization}}, or only delete the NIF membership.",
  "nif.delete_user_from_nif": "Delete {{ name }} from NIF",
  "nif.delete_user_from_nif_info": "You are about to delete the NIF membership for",
  "nif.i_didnt_get_the_email": "I didn’t get the email",
  "nif.i_have_verified": "I have verified",
  "nif.initiate_description_one": "By synchronizing with Norges Idrettsforbund (NIF) we’re assured that membership information up to date. This is vital for the public funding of the club and reduces stress for all those that work hard for the club.",
  "nif.initiate_description_two": "Verification consists of two steps. Add the necessary information here, and then confirm the verification with NIF. If you have children, you will have to repeat the process.",
  "nif.initiate_heading": "We synchronize with Norges Idrettsforbund!",
  "nif.members": "NIF members",
  "nif.membership": "Membership",
  "nif.min_2_characters": "Minimum 2 characters",
  "nif.mobile_number_error": "Please enter a valid mobile number",
  "nif.nationality": "Nationality",
  "nif.next": "Next",
  "nif.nif_gender_info": "NIF only allows the use of biological sex in its registers.",
  "nif.nif_ssn_info": "This information is never stored with us. NIF verifies the personal identification number for Norwegian citizenship directly with the National Register of Citizens.",
  "nif.nif_verified": "NIF-verified",
  "nif.not_a_member": "Not a member",
  "nif.not_verified": "Not Verified",
  "nif.ok_i_understand": "Ok, I understand!",
  "nif.one_more_important_step": "One more important step!",
  "nif.pending": "Pending",
  "nif.phone": "Mobile number",
  "nif.post_code": "Postal Code",
  "nif.processing_information": "Processing Information",
  "nif.search_by_name": "Search",
  "nif.select_who_would_be_verified": "Select who would be verified",
  "nif.ssn_length_validation_message": "Social security number need to be 11 characters",
  "nif.status_member": "Member",
  "nif.status_pending": "Pending",
  "nif.status_unknown": "Unknown",
  "nif.total_groups": "In {{ count }} groups",
  "nif.users": "Users",
  "nif.verification_submission_description": "You will receive an SMS or email from the Norwegian Sports Association confirming the verification in Hoopit. If you do not confirm this within 24 hours, you will have to complete the whole process again. In the meantime, you can use Hoopit as usual!",
  "nif.verified": "Verified",
  "nif.verify": "Verify",
  "nif.verify_form_description": "Here is the information we have about the profile. It is important that you check that the information is correct and you will have to enter what is missing.",
  "nif.verify_nif_membership": "Verify NIF-Membership",
  "nif.verify_user": "Start verification",
  "no_content.back_to_homepage": "Back to homepage",
  "no_content.browser_not_supported": "Browser not supported, please use Microsoft Edge, Google Chrome, Mozilla Firefox, or Safari",
  "no_content.no_claims_found": "No claims found",
  "no_content.no_invoices_found": "No invoices found",
  "no_content.no_invoices_found_group": "No invoices found in {{ group }}",
  "no_content.no_not_paid_payments": "No not paid payments",
  "no_content.no_orders_found": "No orders found",
  "no_content.no_orders_found_group": "No orders found in {{ group }}",
  "no_content.no_payments_found": "No payments found",
  "no_content.no_payments_found_group": "No payments found in {{ group }}",
  "no_content.no_payouts_found_group": "No payouts found in {{ group }}",
  "no_content.no_users_found": "No users found",
  "no_content.no_users_found_group": "No users found in {{ group }}",
  "no_content.ooops": "Ooops!",
  "no_content.page_doesnt_exist": "Sorry, this page doesn't exist.",
  "no_content.page_doesnt_exist_description": "It seems like you are trying to access a page that either has been deleted or never existed.",
  "onboarding.activate_child_user": "Ask a parent to activate your account in the app, in <strong>Child</strong> in settings.",
  "onboarding.add_another_guardian_to": "Add another guardian to",
  "onboarding.add_child_in": "Add your children in",
  "onboarding.add_child_in_other_group": "If you want to add other children in a different team in",
  "onboarding.add_child_to_join_group": "To connect to a group you need to add a child.",
  "onboarding.add_guardian_hint_part_1": "You can connect another guardian to your child using",
  "onboarding.add_guardian_hint_part_2": "or by sharing the registration link to them.",
  "onboarding.add_new_child_in": "Add a new child in",
  "onboarding.add_user": "If your child is <strong>{{ name }}</strong> click <strong>Add</strong> to connect as a parent.",
  "onboarding.already_a_member": "You are already registered in this group.",
  "onboarding.already_registered": "User with this mobile number is already registered. \nPlease, use the following link to login.",
  "onboarding.choose_existing_users": "If you are not sure which user to choose.",
  "onboarding.confirm_user_info": "Do you want to add <strong> {{ name }} </strong> as a new user? Double check that name and birth date are correct \uD83E\uDD13",
  "onboarding.contact_us": "Contact us in the chat on the bottom right",
  "onboarding.create_new_user": "Create new user for {{ firstName }} {{ lastName }}",
  "onboarding.current_members_number": "Members {{ membersNumber }} out of {{ allowedMembersNumber }}",
  "onboarding.enter_auth_phone_number": "Please enter your mobile phone number",
  "onboarding.enter_code": "Enter code",
  "onboarding.enter_code_description": "Please enter the verification code that was sent to your mobile device.",
  "onboarding.enter_full_name": "Please enter your full name without any abbreviations",
  "onboarding.forgot_password": "Forgot your password? Press here.",
  "onboarding.have_user": "Already have a user?",
  "onboarding.join_group": "Joining {{ groupName }}",
  "onboarding.max_members_number_text": "Unfortunately, this group has already reached the maximum limit of users ({{ allowedMembersNumber }})",
  "onboarding.member": "Member",
  "onboarding.multiple_users_found": "Multiple users exists for {{ name }}",
  "onboarding.no_children_in_group_in": "You don't have any children registered in",
  "onboarding.not_correct_user": "Isn't {{ name }} you want to add?",
  "onboarding.one_user_found": "{{ name }} is already registered in Hoopit",
  "onboarding.our_instructions": "our instructions",
  "onboarding.parent": "Parent",
  "onboarding.phone_number_exists": "This phone number is already registered in our system. Try to log in or request a new password.",
  "onboarding.read_on_support": "Read more about user activation here ",
  "onboarding.reason_for_this_info": "If you are a parent, then we need your birthday for identification. Date of birth will not be shown to others except you.",
  "onboarding.register_as_associate_member_description": "Associate members are important to the club and the money goes for a good purpose. In addition, associate members are counted in the financial support we receive from the Norwegian Sports Association.",
  "onboarding.register_as_associate_member_title": "Do you want to register as an associate-member?",
  "onboarding.register_as_member": "Register as member",
  "onboarding.register_as_member_description": "Member participates in the activities organized by this group.",
  "onboarding.register_as_member_title": "Do you want to register as a member?",
  "onboarding.register_as_parent": "Register as parent",
  "onboarding.register_as_parent_info": "If you want to register your child, you first need to register yourself as a parent.",
  "onboarding.register_in_other_group": "If you want to join another team in",
  "onboarding.register_new_user": "Register a new user",
  "onboarding.select_role": "What is your role in {{groupName}}?",
  "onboarding.skip_adding_child": "You have chosen to register as a parent. \nIf you do not add any children, you will not be connected to the group.",
  "onboarding.to_registration": "To Club Registration",
  "onboarding.user_should_exists": "If you know or believe that <strong> {{ name }} </strong> is already registered, you can contact us, a team leader, or other guardians for <strong>{{ name }}</strong>.",
  "onboarding.welcome_to": "Welcome to",
  "onboarding.why_we_need_birthday": "Why we need this information?",
  "onboarding.your_children": "Your registered children",
  "onboarding.your_children_in": "Your children registered in",
  "onboarding.your_children_in_waiting_list": "Your children in the waiting line of",
  "orders.bought_out": "Bought-out",
  "orders.confirmed": "Confirmed",
  "orders.edit_dugnad_order_description_1_1": "{{ name }} has to order at least {{ number }} items in total.",
  "orders.edit_dugnad_order_description_1_2": "Person can distribute the number of items among the available products until the confirmation due date expires or the order is confirmed.",
  "orders.edit_dugnad_order_description_2_1": "{{ name }} has to order no more than {{ number }} items in total.",
  "orders.edit_dugnad_order_description_2_2": "Person can distribute the number of items among the available products until the confirmation due date expires or the order is confirmed.",
  "orders.edit_dugnad_order_description_3_1": "{{ name }} has to purchase at least {{ number }} items in total.",
  "orders.edit_dugnad_order_description_3_2": "Person can distribute the number of items among the available products.",
  "orders.edit_dugnad_order_description_4_1": "{{ name }} has to purchase no more than {{ number }} items in total.",
  "orders.edit_dugnad_order_description_4_2": "Person can distribute the number of items among the available products.",
  "orders.exempted": "Exempted",
  "orders.items": "Items",
  "orders.not_confirmed": "Not confirmed",
  "orders.number_of_items_for": "Number of items for",
  "organization.add_admin": "Add admin",
  "organization.add_guardian": "Add guardian",
  "organization.add_members": "Add members",
  "organization.add_members_to_another_group": "Add {{ number }} members to another group?",
  "organization.add_member_to_another_group": "Add {{ name }} to another group?",
  "organization.add_player": "Add player",
  "organization.add_to_another_group": "Add to another group",
  "organization.admin_is_being_deleted": "{{ firstName }} {{ lastName }} is being deleted as an admin, this make take some time",
  "organization.admins_are_being_deleted": "{{ adminsCount }} admins are being deleted, this make take some time",
  "organization.admins_that_will_be_deleted": "Admins that will be deleted:",
  "organization.all_posts": "All records",
  "organization.attendance": "Attendance",
  "organization.attendance_statistics": "Attendance statistics",
  "organization.chat": "Chat",
  "organization.contact_info": "Contact Info",
  "organization.delete_admin": "Delete admin",
  "organization.delete_admins": "Delete admins",
  "organization.delete_admins_description": "Are you sure you want to delete <b>{{ adminsCount }}</b> admins from <b>{{ group }}</b>?",
  "organization.delete_from_current_group": "Delete from current group",
  "organization.delete_member": "Delete member",
  "organization.delete_member_description_1": "Are you sure you want to delete <b>{{ firstName }} {{ lastName }}</b> from <b>{{ group }}</b>?",
  "organization.delete_member_description_2": "They will still be registered in the system and may be added again later.",
  "organization.delete_player": "Delete player",
  "organization.delete_players": "Delete players",
  "organization.delete_players_description": "Are you sure you want to delete <b>{{ playersCount }}</b> players from <b>{{ group }}</b>?",
  "organization.delete_post": "Delete post?",
  "organization.delete_post_confirmation": "By continuing, the post, as well as all the comments included, will be deleted.",
  "organization.filter": "Filter",
  "organization.filters": "Filters",
  "organization.in_this_group": "in this group.",
  "organization.invite_members": "Invite members",
  "organization.invite_members_to_another_group": "Invite {{ number }} members to another group?",
  "organization.invite_member_to_another_group": "Invite {{ name }} to another group?",
  "organization.invite_to_another_group": "Invite to another group",
  "organization.invite_to_relevant_groups": "Please invite these members to relevant groups and remove them from <b>{{ group }}</b>.",
  "organization.media_permission_hint": "\"Permits internally\" - member permits posting inside Hoopit; \"Permits publicly\" - member permits posting internally and publicly (outside Hoopit).",
  "organization.member_add_success": "{{ name }} is being added to {{ group }}, this may take some time.",
  "organization.member_invite_success": "{{ name }} is being invited to {{ group }}, this may take some time.",
  "organization.member_move_success": "{{ name }} is being moved to {{ group }}, this may take some time.",
  "organization.member_moving_success_message": "{{ userName }} is moving to {{ groupName }}, this may take some time.",
  "organization.members_add_success": "{{ number }} members are being added to {{ group }}, this may take some time.",
  "organization.members_deleting_success_message": "{{ membersCount }} members are deleting from {{ groupName }}, this may take some time.",
  "organization.members_invite_success": "{{ number }} members are being invited to {{ group }}, this may take some time.",
  "organization.members_moved": "Members that will be moved:",
  "organization.members_move_success": "{{ number }} members are being moved to {{ group }}, this may take some time.",
  "organization.members_moving_success_message": "{{ membersCount }} members are moving to {{ groupName }}, this may take some time.",
  "organization.members_to_add": "Members to add",
  "organization.members_to_invite": "Members to invite",
  "organization.members_to_move": "Members to move",
  "organization.min_characters_message": "Minimum {{ minCharRequirement }} characters",
  "organization.move_members": "Move members",
  "organization.move_members_to_another_group": "Move {{ number }} members to another group?",
  "organization.move_member_to_another_group": "Move {{ name }} to another group?",
  "organization.move_to_another_group": "Move to another group",
  "organization.no_group_selected": "No group selected",
  "organization.no_permission": "No permission",
  "organization.not_set": "Not set",
  "organization.no_users": "No users found",
  "organization.oops_it_seems_that_you_have": "Oops! It seems that you have",
  "organization.permits_internally": "Permits internally",
  "organization.permits_publicly": "Permits publicly",
  "organization.player_is_being_deleted": "{{ firstName }} {{ lastName }} is being deleted as a player, this may take some time",
  "organization.players_are_being_deleted": "{{ playersCount }} players are being deleted, this may take some time",
  "organization.players_that_will_be_deleted": "Players that will be deleted:",
  "organization.posts": "Posts",
  "organization_registration.add_club_account": "Add club account",
  "organization_registration.add_club_account_description": "Please enter your account number in order to handle payments.",
  "organization_registration.bank_account_added": "Bank account has been added!",
  "organization_registration.begin_stripe_integration": "Stripe Connect integration",
  "organization_registration.begin_stripe_integration_description": "Hoopit utilizes Stripe in order to handle payments securely. The next step is to add a bank account so you can create payments in your organization.",
  "organization_registration.check": "Check",
  "organization_registration.club_registration": "team Registration",
  "organization_registration.confirm_personal_information": "Confirm personal information",
  "organization_registration.go_to_stripe": "Go to Stripe Onboarding",
  "organization_registration.invalid_account_number": "The account number provided is not valid",
  "organization_registration.log_in": "login",
  "organization_registration.next": "Next",
  "organization_registration.payments_enabled": "Payments have been enabled!",
  "organization_registration.payments_enabled_message_one": "Stripe Connect verification for your account number <strong> **** ** *{{account_numbers_last_four}} </strong> is completed",
  "organization_registration.payments_enabled_message_two": "The next step is to set up special sports and invite the leaders. These will then be given responsibility for setting up their respective departments.",
  "organization_registration.personal_information_description_one": "In order to create a sports team, it is a requirement that the personal information you have provided is your own.",
  "organization_registration.personal_information_description_two": "You will be registered as an account holder with our payment provider",
  "organization_registration.privacy_policy": "Privacy Policy",
  "organization_registration.proceed_to_stripe_to_verify_account": "Stripe requires some information in order to allow payment processing",
  "organization_registration.register_description_part_one": "To start the Hoopit sports team, you must first register as a board member.",
  "organization_registration.register_description_part_two": "If you have a user before, you can log in and create the sports team with this.",
  "organization_registration.register_personal_information": "Register personal information",
  "organization_registration.register_user_heading": "Fill in your user information as chairman.",
  "organization_registration.register_user_information": "Register user information",
  "organization_registration.set_up_divisions": "Set up Divisions",
  "organization_registration.sign_in_with_existing_user": "Sign in with your existing user.",
  "organization_registration.skip_divisions_descriptions": "When the invites are sent out you will no longer be able to add more division through this link. You may do this afterwards through your Hoopit page.",
  "organization_registration.skip_invitations": "Send out invitations?",
  "organization_registration.step_count": "Step {{number}} of {{total}}",
  "organization_registration.stripe_error_description": "If you see this screen, then one of these problems has occurred: \n - The necessary information was not provided for the successful Stripe Connect \n - Link is outdated \n - A technical error has occurred.  \n Please try again",
  "organization_registration.terms_of_use": "Terms of Use",
  "organization_registration.verification_incomplete": "Verification is almost done",
  "organization_registration.verification_incomplete_decription": "Click the 'next' button to complete the registration to upload your ID and verify your account.",
  "organization_registration.verification_not_complete": "Verification is not complete",
  "organization.search_by_name": "Search by name",
  "organization.select_group": "Select a group from the menu on the left.",
  "organization.select_group_for_addition": "Please select the group members will be added to.",
  "organization.select_group_for_invitation": "Please select the group members will be invited to.",
  "organization.select_group_for_move": "Please select the group members will be moved to.",
  "organization.select_group_warning": "Note that they won't have a jersey number in the new group.",
  "organization.select_member_group": "Please select the group, member will be moved to.<br/>Note that moved player won't have a jersey number in new group.",
  "organization.select_members_group": "Please select the group, members will be moved to.<br/>Note that moved players won't have a jersey number in new group.",
  "organization_selector.companies": "Companies",
  "organization_selector.select_organization": "Select organization",
  "organization.settings": "Settings",
  "organization.this_group_has_x_players": "This group has {{ playersCount }} / {{ allowedPlayersCount }} players",
  "organization.title": "Title",
  "organization.total_admin": "admin in {{ group_name }}",
  "organization.total_admins": "admins in {{ group_name }}",
  "organization.total_guardian": "guardian in {{ group_name }}",
  "organization.total_guardians": "guardians in {{ group_name }}",
  "organization.total_player": "player in {{ group_name }}",
  "organization.total_players": "players in {{ group_name }}",
  "organization.user_deleted_from_group": "{{ userName }} is deleted from {{ groupName }}.",
  "organization.yes_move": "Yes, move",
  "organization.your_posts": "Your records",
  "password.expired": "The link has expired, please request a new reset password request.",
  "password.forgot": "Forgot password",
  "password.link_sent": "A request to reset your password has now been sent to your email.",
  "password.new": "Go to the app to sign in with your mobile number and new password.",
  "password.remembered": "Remembered it",
  "password.reset": "Reset password",
  "password.reset_request_info": "Enter your mobile phone number below and get a reset link sent to your registered email.",
  "password.reset_request_sent": "You will shortly be sent an email where you can reset your password.",
  "password.reset_request_to_spam": "NB! If you do not get a link within a few minutes, check your spam folder.",
  "password.update": "Update password",
  "password.update_success": "Your password is now updated!",
  "payment.": "Payment",
  "payment.account": "Account",
  "payment.all_payments": "All payments",
  "payment.automatic_reminder": "Automatic",
  "payment.automatic_reminder_info": "Automatic reminders are the ones Hoopit are sending out. These are sent out 2, 5 and 7 days after the payment deadline has passed. We also sends a new reminder every week after the payment deadline has passed.",
  "payment.back_to_payment_details": "Back to payment details",
  "payment.count": "Count",
  "payment.custom_price": "Custom price",
  "payment.decision_confirmation_description": "All group-level payments of this training fee will be affected by this action.",
  "payment.decision_confirmation_title": "Apply changes to the whole training fee?",
  "payment.declined": "Declined",
  "payment.delete_modal_description": "{{ paymentName }} and all user payments related to it will be deleted. This action cannot be undone.",
  "payment.delete_modal_description_2": "Note that this payment is used as mandatory to join and after deleting it new players will be able to join these groups without paying",
  "payment.delete_modal_title": "Delete payment?",
  "payment_details.ongoing_payment": "Ongoing payment",
  "payment.due_date": "Due date",
  "payment.edit_user_payment_for": "Edit payment for",
  "payment.exempted": "Exempted",
  "payment.exempted_payments": "Exempted payments",
  "payment.here": "here",
  "payment.last_reminder": "Last reminder",
  "payment.last_reminder_date": "Last reminder date",
  "payment.left_user": "Left user",
  "payment.manage_recipients": "Manage recipients",
  "payment.manual_reminder": "Manual",
  "payment.name": "Name",
  "payment.new_economy": "new economy",
  "payment.new_page_hint": "You can change the sum in the",
  "payment.new_reminder": "New reminder",
  "payment.new_reminder_info": "The original payment message is included anyway. The reminder is sent both in the app and by mail.",
  "payment.new_reminder_into_to_all": "You can add a message to those who have not paid.",
  "payment.new_reminder_into_to_one": "You can add a message to",
  "payment.new_reminder_to": "New reminder to",
  "payment.no_payments_sent": "No payments sent out yet",
  "payment.not_charged": "Not charged",
  "payment.not_paid": "Not paid",
  "payment.not_published": "Not published",
  "payment.ongoing_payments": "Ongoing payments",
  "payment.on_hold": "On hold",
  "payment.optional": "Optional",
  "payment.overdue": "Overdue",
  "payment.paid": "Paid",
  "payment.paid_manually": "Paid manually",
  "payment.past_payments": "Past payments",
  "payment.payment_created": "Payment created",
  "payment.payment_due_date": "Payment due date",
  "payment.payment_publish_date": "Payment publish date",
  "payment.payment_updated": "Payment updated",
  "payment.payment_update_success": "Payment for {{ name }} has been updated",
  "payment.payment_was_successfully_created": "The payment was successfully created",
  "payment.payment_was_successfully_deleted": "{{ paymentName }} is being deleted. This may take some time.",
  "payment.payment_was_successfully_updated": "Payment was successfully updated.",
  "payment.project": "Project",
  "payment.publish": "Publish",
  "payment.publish_date": "Publish date",
  "payment.publish_payment_modal_description": "Set new publish date for {{ paymentName }}.",
  "payment.publish_payment_modal_description_2": "Note that this payment is used as mandatory to join and after republishing it new players will be given 15 minutes to pay in order to join these groups",
  "payment.publish_payment_modal_title": "Publish payment?",
  "payment.put_on_hold": "Put on hold",
  "payment.put_on_hold_modal_description": "Status of {{ paymentName }} will be changed to “On hold” and users will not be able to pay for this payment in app. You can republish this payment at any time.",
  "payment.put_on_hold_modal_description_2": "Note that this payment is used as mandatory to join and after putting it on hold, new players won’t be able to join these groups",
  "payment.put_on_hold_modal_title": "Put payment on hold?",
  "payment.quantity": "Quantity",
  "payment.read_more_about_payments": "Read more about payments in Hoopit",
  "payment.reminder_history": "Reminder history",
  "payment.reminder_history_description": "Hoopit sends out automatic reminders 2, 5 and 7 days after payment deadline has passed and then once a week. Reminders are sent as emails and push notifications in the app. You can also send new reminder manually.",
  "payment.reminder_history_for": "Reminder history for",
  "payment.reminder_notification_info": "Reminders are sent as emails and push notifications to anyone with the app.",
  "payment_report_list.no_payment_reports": "No payment reports",
  "payment_report_list.payment_report": "Payment Report",
  "payment_report_list.payout": "Payout",
  "payment_reports.account_number": "Account number",
  "payment_reports.account_number_tokenized": "\nAccount number: **** ** *",
  "payment_reports.arrival_date": "Expected on account",
  "payment_reports.bank_account_payments_description": "Payments to your bank account are marked «SAXO PAYMENTS A/S». This company handles all payments in the system.",
  "payment_reports.date": "Date",
  "payment_reports.error_occured": "An error has occurred in the system, cannot retrieve the attachments for the period",
  "payment_reports.group": "Group",
  "payment_reports.hide_all_transactions": "Hide all transactions",
  "payment_reports.name": "Name",
  "payment_reports.net_to_account": "Net to account",
  "payment_reports.no_arrival_date": "Not paid yet",
  "payment_reports.no_payment": "No payment for period",
  "payment_reports.outstanding": "Outstanding",
  "payment_reports.paid": "Paid",
  "payment_reports.payment": "Payment",
  "payment_reports.payment_description": "Payments to your bank account are marked «SAXO PAYMENTS A/S».\nThis company handles all payments in the system.",
  "payment_reports.payment_report": "Payment report",
  "payment_reports.pays": "Pays",
  "payment_reports.period": "Period",
  "payment_reports.sum": "Sum",
  "payment_reports.sum_for": "Sum for",
  "payment_reports.total_amount": "Total sum",
  "payment_reports.total_fee": "Total transaction fee",
  "payment_reports.total_paid": "Sum paid out",
  "payment_reports.transaction_fee": "Transaction fee",
  "payment_reports.transactions_for": "Transactions for",
  "payment_reports.transferred_to": "Transferred to {{ account_name }}",
  "payment_reports.transferred_to_main_account": "Transferred to Main Account",
  "payment_reports.view_all_transactions": "View all transactions",
  "payment.send_reminder": "Send new reminder",
  "payment.send_reminders": "Send reminders",
  "payment.set_custom_price": "Set custom price",
  "payment.sum": "Sum",
  "payment_table.outstanding": "Outstanding",
  "payment_table.paid": "Paid",
  "payment.tf_is_being_created": "The payment is being created. This may take some time.",
  "payment.to_see_transaction_fee": "to see precise transaction fee and control whether it is included in the price.",
  "payment.total": "Total",
  "payment.total_payments": "Payments sent",
  "payment.total_sum": "Total sum",
  "payment.type": "Type",
  "payment.upcoming_payments": "Upcoming payments",
  "payment.use": "Use the",
  "payment.user_payment_price_categories_description": "This is the available price category to {{ firstName }} {{ lastName }}. You can also set a custom amount instead.",
  "payment.user_payment_price_categories_description_2": "These are the available price categories to {{ firstName }} {{ lastName }}. You can also set a custom amount instead.",
  "payouts.arrival_date": "Arrival date",
  "payouts.by_arrival_date": "By arrival date",
  "payouts.download": "Download report (PDF)",
  "payouts.failed": "Failed",
  "payouts.in_progress": "In progress",
  "payouts.insufficient_funds": "Insufficient funds",
  "payouts.insufficient_funds_information": "We will start the payout process as soon as Net to account reaches 500 NOK or a month has passed",
  "payouts.in_transfer": "In transfer",
  "payouts.in_transfer_information": "Payout is already processing by Stripe.",
  "payouts.open": "Open",
  "payouts.paid_out": "Paid out",
  "payouts.payouts_label_hint": "Each payout contains all payments and fees for selected time period. You can download reports for paid out payouts.",
  "payouts.ready_for_transfer": "Ready for transfer",
  "payouts.ready_for_transfer_information": "Will start processing by Stripe as soon as possible.",
  "payouts.status": "Status",
  "payouts.total_net_to_account": "Total net to account: {{ netAmount }}",
  "post_create.add_attachment": "Add attachment",
  "post_create.added_attachments": "Added attachments",
  "post_create.added_photos": "Added photos",
  "post_create.add_images": "Add your images here",
  "post_create.add_photos": "Add photo",
  "post_create.add_post": "Write your entry here",
  "post_create.add_post_title": "Write a title for the record",
  "post_create.attachments": "Add attachments",
  "post_create.change": "Change",
  "post_create.choose_different_group": "Choose a different group?",
  "post_create.delete_photo": "Delete photo?",
  "post_create.disable_comments": "Disable and hide comments",
  "post_create.edit": "Edit",
  "post_create.everyone": "Everyone",
  "post_create.file_size_validation_message": "File size should not be bigger than {{ maxFileSize }}MB",
  "post_create.file_type_validation_message": "{{ type }} files are not allowed. Allowed types: {{ allowedTypes }}",
  "post_create.for": "for",
  "post_create.format_not_supported": "this file format is not supported",
  "post_create.in": "in",
  "post_create.leaders_guardians": "Leaders and guardians",
  "post_create.loading": "Loading...",
  "post_create.max_number_validation_message": "Maximum number of attachments for the post is {{ maxNumber }}",
  "post_create.new": "New",
  "post_create.no_one_will_be_able_to_comment_on_this_post.": "No one will be able to comment on this post.",
  "post_create.not_enough_credits_hint": "Not enough credits. Contact organization administrators to use this feature.",
  "post_create.notification_methods_description": "By default, users get an in-app notification, but here you can choose extra ways to make sure everyone sees this post.",
  "post_create.notification_methods_title": "Additional ways to notify members",
  "post_create.notify_members_via_sms": "Notify via SMS",
  "post_create.notify_members_via_sms_description": "We will send an SMS notification to each member of the selected group of people about publication of this post.",
  "post_create.notify_via_email": "Notify via email",
  "post_create.notify_via_email_description": "We will send an email to all users who should view the post. Note that even if you don't choose this option, users who didn’t get an in-app notification will still receive an email.",
  "post_create.photo": "Picture",
  "post_create.post": "post",
  "post_create.post_confirmation": "Who do you want to see the post?",
  "post_create.post_images_limit": "There is a limit of 8 images in each record.",
  "post_create.post_publish_question": "In which groups should this post be published?",
  "post_create.post_save_confirmation_description": "Do you want to notify members of this group about changes in this post?",
  "post_create.post_save_confirmation_title": "Save and notify members about changes?",
  "post_create.select_specific": "Want to select something specific?",
  "post_create.send_message_question": "Where do you want to send the message?",
  "post_create.share_message": "Share a message with the group, department or club.",
  "post_create.this_group": "Just this group",
  "post_create.this_group_subgroups": "This group and all subgroups",
  "post_create.title": "Title",
  "post_create.upload_failed_message": "Failed to upload file",
  "post_create.visible_to": "Visible to",
  "post_detail.comment": "Comment",
  "post_detail.comments": "Comments",
  "post_detail.comments_are_disabled": "Comments are disabled for this post by author.",
  "post_detail.delete_comment": "Delete comment?",
  "post_detail.delete_post": "Delete",
  "post_detail.edit_post": "Edit",
  "post_detail.everyone_seen_message": "Everyone has seen the post!",
  "post_detail.no_one_seen_message": "No one has seen the post yet!",
  "post_detail.not_seen": "Not seen",
  "post_detail.post_image": "Picture in post",
  "post_detail.post_views_not_seen_message": "{{knownCount}} people from your groups didn’t see this post, along with {{unknownCount}} from other groups.",
  "post_detail.post_views_seen_message": "{{knownCount}} people from your groups viewed this post, along with {{unknownCount}} from other groups.",
  "post_detail.seen": "Seen",
  "post_detail.seen_by_hint": "Only people using Hoopit application (app, web app or admin panel)",
  "post_detail.seen_status": "Post views",
  "post_detail.subgroups": "Subgroups",
  "post_detail.visible_to_leaders": "Visible to managers",
  "post_detail.visible_to_leaders_guardians": "Visible to leaders and guardians",
  "post_detail.write_comments": "Write your comments here",
  "post_file.attachment_name": "Name of attachment",
  "post_file.delete_attachment": "Delete this attachment?",
  "post_file.file_with_similar_name": "File with a similar name",
  "post_file.file_with_similar_name_description": "You are trying to add a file with a name that already exists, are you sure? You can rename it afterwards.",
  "post_file.name_max_length_validation_message": "Attachment name should not be longer than 251 character",
  "post_file.rename_attachment": "Rename attachment",
  "posts.by_groups": "By groups",
  "posts.create_post_text": "Be the first! Create post using “New post” button.",
  "posts.delete_post": "Delete post?",
  "posts.delete_post_confirmation": "By continuing, the post, as well as all the comments included, will be deleted.",
  "posts.filter": "Filter",
  "posts.filters": "Filters",
  "posts.new_post": "New post",
  "posts.no_groups": "No groups found",
  "posts.no_posts": "No posts added",
  "posts.no_results": "No results found",
  "posts.notified_by_sms": "Notified by SMS",
  "posts.other_filters_text": "Please try other filters",
  "posts.search_by_post_title": "Search by title",
  "posts.title_min_length_validation_message": "Title must have at least 2 characters",
  "profile_picture.delete": "Delete image",
  "profile_picture.delete_profile_picture": "Delete profile picture",
  "profile_picture.delete_profile_picture_description": "Are you sure you want to remove this user's profile picture?",
  "profile_picture.edit": "Edit",
  "profile_picture.profile_picture_title": "Profile picture",
  "profile_picture.upload": "Upload",
  "registered_members_list.club": "club-",
  "registered_members_list.connected_account": "Connected Account",
  "registered_members_list.division": "Division",
  "registered_members_list.export_excel": "Export excel for the entire club",
  "registered_members_list.group": "Group",
  "registered_members_list.member_list": "memberlist-",
  "registered_members_list.members_registered": "Members registered",
  "registered_members_list.parents_registered": "Parents registered",
  "registered_members_list.register": "Register",
  "registered_members_list.registered_invited": "Registered/invited",
  "registered_members_list.team_leader": "Team leader",
  "registered_members_list.teams_overview_message": "Here you can see an overview of all the teams in the sports team, how many who have been invited and how many who have registered.",
  "registration_links.about_hoopit": "About Hoopit",
  "registration_links.after_signup_message": "When you are done signing up, just download the Hoopit app from App Store or Google Play and log in. There you will find all your activities \uD83D\uDE03",
  "registration_links.contact_hoopit": "If you have questions or need help, you can also contact Hoopit in the chat on the bottom right.",
  "registration_links.get_started_hoopit": "Here's how to get started with Hoopit",
  "registration_links.join_groups_welcome_message": "Here are all the groups in",
  "registration_links.join_groups_welcome_message_2": "you can join! To join several groups, you must return to this page.",
  "registration_links.log_in": "Sign in",
  "registration_links.log_out": "Log out",
  "registration_links.no_groups_to_register": "There are no groups you can register at this time!",
  "registration_links.registration_links_message": "Here you will find registration links to all groups in the special sports in",
  "registration_links.registration_links_message_2": "so you as a parent or practitioner can register. The groups appear here continuously as they are created by the management.",
  "registration_links.support": "Support",
  "registration_links.teams_groups_registration_description": "Below you can see all the groups in the club. Click on the link for your group and you will get to the page where you register yourself and your children. If you have more children in different groups go back to this page and click on the link to the correct team and register your child. If you want to register more children in different groups, you can log in directly to these groups after registering for the first time.",
  "sms.cost": "Cost",
  "sms.credit": "credit",
  "sms.credits": "credits",
  "sms.current_balance": "Current balance",
  "sms.economy_manager_role_is_required": "“Economy manager” role is required",
  "sms.not_enough_credits": "Not enough credits",
  "sms.not_enough_credits_description": "It seems like the last credits have been used up a moment ago. Contact organization administrators to refill the balance.",
  "sms.not_enough_credits_hint": "Not enough credits. Contact organization administrators to use this feature.",
  "sms.notify_via_sms": "Notify via SMS",
  "sms.notify_via_sms_description": "We will send an SMS notification to remind the person about this payment.",
  "sms.price_description": "A standard SMS costs 1 credit.",
  "sms.publish_without_sms_notification": "Publish without SMS notification",
  "sms.request_more": "Order more",
  "sms.sender_name": "Sender name",
  "sms.sender_name_description": "This will be visible to receivers.",
  "sms.sender_name_description_2": "Note that the first character must be a letter. The rest can be either letters, numbers or space.",
  "sms.sender_name_hint": "2-11 letters and numbers with first one being a letter",
  "sms.sender_name_warning": "Choose the best sender name",
  "sms.sender_name_warning_description": "You can use the name of your organization. This way members will receive SMS notifications on behalf of your club.",
  "sms.sms_configuration_description": "Club and division administrators have the option to enhance communication effectiveness by sending SMS along with push notifications and emails. We're continually expanding SMS notifications to more features, so feel free to reach out to support for updates and to share your feedback.",
  "sms.sms_package_purchases": "SMS package purchases",
  "sms.sms_package_purchases_description": "Purchase SMS package here to enable quick and direct communication with your members.",
  "sms.subscription_active": "Subscription active",
  "sms.subscription_unavailable": "Subscription unavailable",
  "stripe_onboarding.additional_info_required": "Stripe requires additional information",
  "stripe_onboarding.additional_update_required": "Stripe requires additional information. Please update.",
  "stripe_onboarding.contact_organization_message": "Stripe requires additional information. Please contact an organization representative at {{ organization_name }} in order to provide the required information",
  "stripe_onboarding.information_required": "Information required",
  "stripe_onboarding.update_information": "Update Information",
  "stripe_onboarding.update_stripe_information": "Update Stripe information",
  "stripe_onboarding.verification_pending": "Stripe verification is in progress",
  "table.account": "Account",
  "table.action": "Action",
  "table.age": "Age",
  "table.age_group": "Age group",
  "table.arrival_date": "Arrival date",
  "table.birth_date": "Birth date",
  "table.claim_name": "Claim name",
  "table.claim_status": "Claim status",
  "table.comments": "Comments",
  "table.confirmation_due_date": "Order due date",
  "table.confirmation_status": "Confirmation status",
  "table.created_by": "Created by",
  "table.created_for": "Created for",
  "table.current_balance": "Current balance",
  "table.customer_supplier": "Customer/Supplier",
  "table.date": "Date",
  "table.date_of_birth": "Birth date",
  "table.description": "Description",
  "table.division": "Division",
  "table.due_date": "Due date",
  "table.dugnad_name": "Dugnad name",
  "table.email": "Email",
  "table.end": "End",
  "table.end_date": "End date",
  "table.event_name": "Event name",
  "table.expected_net_amount": "Expected net amount",
  "table.gender": "Gender",
  "table.generation_date": "Generation date",
  "table.gross_amount": "Gross amount",
  "table.group": "Group",
  "table.groups": "Groups",
  "table.group_type": "Group type",
  "table.has_certificate": "Has certificate",
  "table.hoopit_account": "Hoopit account",
  "table.information": "Information",
  "table.invoice_number": "Invoice number",
  "table.jersey_number": "Jersey number",
  "table.joined": "Joined",
  "table.joined_at": "Joined",
  "table.last_confirmation": "Last confirmation attempt",
  "table.last_edited_by": "Last edited by",
  "table.last_invitation": "Last invitation sent",
  "table.last_invoice": "Last invoice",
  "table.last_nif_confirmation_reminder": "Last NIF confirmation reminder",
  "table.last_reminder": "Last reminder",
  "table.left": "Left",
  "table.location": "Location",
  "table.luxsave_keyword": "LuxSave keyword",
  "table.media_permissions": "Media permissions",
  "table.member": "Member",
  "table.name": "Name",
  "table.nationality": "Nationality",
  "table.net_amount": "Net amount",
  "table.net_to_account": "Net to account",
  "table.nif_confirmation_status": "NIF confirmation status",
  "table.nif_id": "NIF person Id",
  "table.nif_membership": "NIF membership",
  "table.nif_status": "NIF status",
  "table.nif_status_details": "NIF status details",
  "table.no_dugnads_found": "No dugnads found",
  "table.no_dugnads_found_group": "No dugnads found in {{ group }}",
  "table.no_members_yet": "No members yet",
  "table.no_results": "No results found",
  "table.no_results_group": "No results found in {{ group }}",
  "table.notes": "Notes",
  "table.not_paid": "Not paid",
  "table.number_of_items": "# of items",
  "table.optional": "Optional",
  "table.order_progress": "Order progress",
  "table.order_status": "Order status",
  "table.outstanding": "Outstanding",
  "table.outstanding_payment": "Outstanding payment",
  "table.paid": "Paid",
  "table.paid_membership_fee": "Paid MF",
  "table.paid_status": "Paid",
  "table.paid_training_fee": "Paid TF",
  "table.pay_from": "Pay from",
  "table.payment_date": "Payment date",
  "table.payment_due_date": "Payment due date",
  "table.payment_name": "Payment name",
  "table.payment_progress": "Payment progress",
  "table.payment_status": "Payment status",
  "table.payout_status": "Payout status",
  "table.pay_to": "Pay to",
  "table.period": "Period",
  "table.period_end": "Period end",
  "table.period_start": "Period start",
  "table.person_id": "Person Id",
  "table.phone_number": "Mobile number",
  "table.price": "Price",
  "table.price_category_name": "Price category name",
  "table.processed_by": "Processed by",
  "table.processed_date": "Processed date",
  "table.progress": "Progress",
  "table.publish_date": "Publish date",
  "table.push_notification": "Push notification",
  "table.quantity": "Quantity",
  "table.receiver": "Receiver",
  "table.refunds": "Refunds",
  "table.reminder": "Reminder",
  "table.reminder_type": "Reminder type",
  "table.request_date": "Request date",
  "table.request_status": "Request status",
  "table.requires_certificate": "Requires certificate",
  "table.role": "Role",
  "table.sms": "SMS",
  "table.something_went_wrong": "Something went wrong",
  "table.start": "Start",
  "table.status": "Status",
  "table.sub_accounts": "Sub-accounts",
  "table.sub_group": "Sub-group",
  "table.sum": "Sum",
  "table.survey_progress": "Survey progress",
  "table.tag_name": "Tag name",
  "table.tasks": "Tasks",
  "table.title": "Title",
  "table.total_sum": "Total sum",
  "table.total_to_reimburse": "Total to reimburse",
  "table.transaction_fee": "Transaction fee",
  "table.type": "Type",
  "table.updated_by": "Updated by",
  "table.venue_section": "Venue section",
  "table.verified": "Verified",
  "table.verified_by": "Verified by",
  "table.verified_by_nif": "Verified by NIF",
  "table.voucher": "Voucher",
  "table.voucher_number": "Voucher number",
  "table.year": "Year",
  "team_account.allocated_sum": "Allocated to sub-accounts",
  "team_account.available_sum": "Available for {{group}}",
  "team_account.balance_at_end": "Balance at period end",
  "team_account.balance_at_start": "Balance at period start",
  "team_account.change": "Change",
  "team_account.change_hint": "The change is calculated by subtracting outgoing transactions from incoming transactions for the selected period. ",
  "team_account.current_balance": "Current balance",
  "team_account.group_economy": "Group economy",
  "team_account.incoming": "Incoming",
  "team_account.invalid_group_type_description": "To view account balance, please select a group that is integrated with <b>Catacloud</b>. ",
  "team_account.no_permission_description": "Only <b>economy managers</b> of this group or groups above  have access to this page.",
  "team_account.no_transactions_found": "No transactions found",
  "team_account.outgoing": "Outgoing",
  "team_account.page_title": "Team accounts",
  "team_account.preview_description": "This is a preview of “Team accounts” feature. We import accounting data from Catacloud, and show you team's available funds and transactions, directly in Hoopit!",
  "team_account.search_by_anything": "Search by anything",
  "team_account.search_by_group_name": "Search by group name",
  "team_account.set_specific_period": "Set specific period",
  "terms.accept": "Accept",
  "terms.accept_to_continue": "To continue, you must accept our",
  "terms.and": "and",
  "terms.data_policy": "Privacy Policy",
  "terms.privacy_policy_validation_message": "You must accept our Privacy Policy",
  "terms.terms_of_service": "Terms of Use",
  "terms.terms_validation_message": "You must accept our Terms of Use",
  "toaster.404": "We couldn't find what you're looking for :(",
  "toaster.account_set": "The account was set up",
  "toaster.activation_email_sent": "Activation email has been sent to user",
  "toaster.active": "active",
  "toaster.admin_added": "The admin has been added to the group",
  "toaster.already_registered": "is already registered as guardian for",
  "toaster.branch_membership_cancelled": "The sport membership for {{ name }} has been cancelled, and the user has been removed from all related groups in Hoopit.",
  "toaster.cannot_add_fix_id": "cannot add fiksId",
  "toaster.cannot_remove_title": "You do not have permission to remove the title in this group",
  "toaster.changes_were_saved": "Changes were saved!",
  "toaster.club_membership_cancelled": "The club membership for {{ name }} has been cancelled, and the user has been removed from all related groups in Hoopit.",
  "toaster.compress_failed": "Could not compress image",
  "toaster.copied": "Copied!",
  "toaster.emails_dont_match": "“{{email}}” doesn’t match our records and may be outdated or incorrect",
  "toaster.event_reopened": "“{{event}}” was reopened",
  "toaster.fetch_invited_failed": "Failed to fetch invited users",
  "toaster.fix_id_saved": "FiksId saved",
  "toaster.format_not_supported": "File format is not supported",
  "toaster.group_updated": "Group information updated",
  "toaster.has_title": "already has the title",
  "toaster.inactive": "inactive",
  "toaster.invalid_password": "Invalid password",
  "toaster.is_provided": "is now provided for",
  "toaster.location_added": "Location successfully added",
  "toaster.location_deleted": "Location deleted",
  "toaster.location_updated": "Location successfully updated",
  "toaster.new_activation_email_sent": "New activation email sent to",
  "toaster.no_access_to_perform_action": "You do not have access to perform this action.",
  "toaster.no_longer_admin": "{{ user }} is no longer an admin in {{ group }}",
  "toaster.no_longer_member": "{{ user }} is no longer a member of {{ group }}",
  "toaster.no_longer_player": "{{ user }} is no longer a player in {{ group }}",
  "toaster.not_valid_fix_id": "FiksId not valid",
  "toaster.player_added": "The player has been added to the group",
  "toaster.post_deleted": "The post has been deleted",
  "toaster.post_made": "New post created",
  "toaster.post_saved": "The post is saved",
  "toaster.post_updated": "The post was successfully updated.",
  "toaster.post_updated_notified": "The post was successfully updated.\n Notifications were sent.",
  "toaster.remind_unanswered_success_bulk": "A reminder is being sent to {{x}} users and their guardians",
  "toaster.remind_unanswered_success_single": "A reminder is being sent to {{user}} and their guardians",
  "toaster.requested_nif_connection_bulk": "A request to connect to NIF was sent to {{x}} users",
  "toaster.requested_nif_connection_single": "A request to connect to NIF was sent to {{user}}",
  "toaster.select_admin_title": "Select admin title",
  "toaster.sent_nif_confirmation_request": "NIF has sent a confirmation request to “{{email}}”",
  "toaster.something_went_wrong": "Something went wrong",
  "toaster.something_wrong": "Something went wrong",
  "toaster.something_wrong_compress_failed": "Something went wrong, could not compress the image",
  "toaster.unable_to_send_nif_request": "We couldn’t send a request to {{x}} users due to missing email address",
  "toaster.user_saved": "The user is saved",
  "toaster.venues_retrieve_failure": "Could not get venues",
  "toast.title_added_success": "{{ name }} is now {{ title }} in {{ group }}",
  "toast.title_removed_success": "{{ name }} is no longer {{ title }} in {{ group }}",
  "user_activation_status": "User status",
  "user_activation_status.active": "Active",
  "user_activation_status.awaits_membership": "Waiting for membership",
  "user_activation_status.child_user": "Child user",
  "user_activation_status.no_receives_email_updates": "Doesn’t receive updates. Please provide an email.",
  "user_activation_status.not_accepted_group_invitation": "Not accepted group invitation",
  "user_activation_status.not_completed_registration": "Not completed registration",
  "user_activation_status.parents_notification": "The parents receive updates and monitor the child's actions.",
  "user_activation_status.receives_app_updates": "Receives in-app updates",
  "user_activation_status.receives_email_updates": "Receives updates via email",
  "user_activation_status.supervised": "Supervised by guardians",
  "user_attributes.address": "Address",
  "user_attributes.age": "years",
  "user_attributes.birth_date": "Birthdate",
  "user_attributes.birth_day": "Day",
  "user_attributes.birth_month": "Month",
  "user_attributes.birth_year": "Year",
  "user_attributes.birth_year_label": "Birth year",
  "user_attributes.city": "Location",
  "user_attributes.email": "Email",
  "user_attributes.first_name": "First name",
  "user_attributes.gender": "Gender",
  "user_attributes.jersey_number": "Jersey number",
  "user_attributes.last_name": "Last name",
  "user_attributes.last_online": "Last online",
  "user_attributes.name": "Name",
  "user_attributes.nationality": "Nationality",
  "user_attributes.no_permission": "No permission",
  "user_attributes.no_permission_label": "Does not permit photo and video posting",
  "user_attributes.number": "Number",
  "user_attributes.paid_membership_fee": "Paid membership fee",
  "user_attributes.paid_training_fee": "Paid training fee",
  "user_attributes.password": "Password",
  "user_attributes.permission_internal_label": "Permits photo and video posting inside Hoopit",
  "user_attributes.permission_internal_text": "I permit posting internally (inside Hoopit)",
  "user_attributes.permission_public_label": "Permits photo and video posting publicly",
  "user_attributes.permission_public_text": "I permit posting internally and publicly (outside Hoopit)",
  "user_attributes.permissions_description": "Changes will only affect future postings, already published postings can not be affected.",
  "user_attributes.permissions_text": "Please set the permissions on publishing photos and videos of you.",
  "user_attributes.permissions_text_2": "Please set the permissions on publishing photos and videos of {{ name }}.",
  "user_attributes.phone_code": "Code",
  "user_attributes.phone_number": "Mobile number",
  "user_attributes.photo_permission": "Photo permission",
  "user_attributes.postal_code": "Zip code",
  "user_attributes.receives_notifications_via_email": "Receives notifications via email",
  "user_attributes.social_security": "Social security number",
  "user_attributes.street_address": "Street address",
  "user_form_validation.duplicate_user": "It looks like you are already registered!",
  "user_form_validation.email": "Please enter a valid email",
  "user_form_validation.error": "Error!",
  "user_form_validation.name": "First name is all names except the last surname",
  "user_form_validation.password": "Password must have at least 6 characters",
  "user_form_validation.phone_number": "Please enter a valid mobile number",
  "user_profile.activate": "Activate",
  "user_profile.activation_message": "Once you have activated the user, we will send them an email to create a password. <br/> They log in with the mobile number from here.",
  "user_profile.add": "Add",
  "user_profile.add_existing_guardian": "Add existing guardian",
  "user_profile.add_existing_user": "Add existing user",
  "user_profile.add_guardian": "Add guardian",
  "user_profile.add_guardian_label": "Add guardian by name",
  "user_profile.additional_information": "Additional information",
  "user_profile.address_not_provided": "Address not provided",
  "user_profile.authorization_code_label": "Set guardian with authorization code",
  "user_profile.by_email": "by email",
  "user_profile.children": "Children",
  "user_profile.click_here": "click here",
  "user_profile.dob_not_provided": "Date of birth not stated",
  "user_profile.edit_jersey_number": "Edit jersey number",
  "user_profile.edit_profile": "Edit Profile",
  "user_profile.email_not_provided": "Email not provided",
  "user_profile.enter_password_message": "Please enter a verification password",
  "user_profile.fetching_code": "Fetching code...",
  "user_profile.gender_not_provided": "Gender not stated",
  "user_profile.groups": "Groups",
  "user_profile.guardian_in": "Guardian in",
  "user_profile.guardian_of": "as the guardian of",
  "user_profile.guardians_code_message": "Guardians can enter this code into the app&#39;s settings. <br/> Then they will be set as guardians for {{ first_name }}.",
  "user_profile.in": "in",
  "user_profile.info": "Info",
  "user_profile.inquire_add_message": "Do you want to add",
  "user_profile.invitation": "Invitation",
  "user_profile.invitation_sent": "Invitation sent",
  "user_profile.main_information": "Main information",
  "user_profile.manage_roles_description": "Choose which roles <b>{{ user }}</b> should have within <b>{{ group }}</b>.",
  "user_profile.memberships": "Memberships",
  "user_profile.memberships_admin_description": "The member has administrative rights in this group and all its subgroups.",
  "user_profile.memberships_empty_state_description": "Looks like {{userName}} is not a member of any groups you have access to.",
  "user_profile.memberships_empty_state_title": "No memberships",
  "user_profile.memberships_manage_roles": "Manage roles",
  "user_profile.memberships_player_description": "The member actively participates in group activities and pays fees.",
  "user_profile.memberships_roles_article_button": "this article",
  "user_profile.memberships_roles_counter": "+{{ x }} more",
  "user_profile.memberships_roles_description": "Roles allow admins to manage groups more efficiently. E.g. “Økonomiansvarlig” lets user to access group payments. You can learn more about Hoopit roles in",
  "user_profile.mobile_number_not_provided": "Mobile number not provided",
  "user_profile.no_guardians": "No guardians",
  "user_profile.no_guardians_description": "Looks like no one is connected to {{ name }} as their guardian",
  "user_profile.no_results": "No results for",
  "user_profile.no_results_found": "No results found",
  "user_profile.no_user_by_email": "We couldn’t find users with this email",
  "user_profile.no_user_by_name": "We couldn’t find users with this name",
  "user_profile.number_in_use_error": "There is already a user with this phone number. Please try different number or delete the user and invite them again using the correct number in Groups > Users.",
  "user_profile.or": "or",
  "user_profile.payments_tab_description": "Here is the list of all not paid payments of this user. To see all payments",
  "user_profile.payments_tab_description_1": "Here is the list of all not paid payments of this user.",
  "user_profile.payments_tab_description_2": "To see all payments",
  "user_profile.permissions": "Permissions",
  "user_profile.phone_number_usage": "Phone number usage",
  "user_profile.phone_number_usage_description": "Please note that for {{ name }} to successfully complete the registration and accept the invitation, they must use the specified phone number.",
  "user_profile.practicing_in": "Practicing in",
  "user_profile.profile": "Profile",
  "user_profile.relations": "Relations",
  "user_profile.remind_via_email": "Remind via email",
  "user_profile.remove": "Remove",
  "user_profile.remove_as_admin": "Remove as an admin",
  "user_profile.remove_as_player": "Remove as a player",
  "user_profile.remove_from_group_description": "Do you want to remove <b>{{ user }}</b> from <b>{{ group }}</b>?",
  "user_profile.remove_from_group_title": "Remove from group?",
  "user_profile.remove_from_group.website_access_lost_description": "This is your only admin role in {{ organization }}. Removing it will result in losing access to this website.",
  "user_profile.remove_from_group.website_access_lost_title": "You will lose access to this website",
  "user_profile.remove_title": "Remove title",
  "user_profile.remove_title_description": "Are you sure you want to remove title {{ title }} in {{ groupName }} for {{ userName }}?",
  "user_profile.resend_invitation_button": "Resend invitation",
  "user_profile.resend_invitation_by_sms_description": "{{ name }} will receive the invitation by SMS at <b>{{ number }}</b>, sent by {{ sender }}. If this number is wrong, please delete this user in “Groups” > “Users” and invite again with the correct number.",
  "user_profile.resend_invitation_by_sms_title": "Invitation by SMS",
  "user_profile.resend_invitation_description": "Send an invitation link to {{ name }}, reminding that in order to join the group and become an active member of {{ organization }}, they need to complete registration. ",
  "user_profile.resend_invitation_description_2": "If you provide {{ name }}'s email, we'll also send it there for easy access.",
  "user_profile.resend_invitation_email": "Resend invitation email",
  "user_profile.resend_invitation_email_description": "You can resend the invitation email to a user who is not yet registered in Hoopit.",
  "user_profile.resend_invitation_reminder": "Last invitation was sent on {{ datetime }}.",
  "user_profile.roles": "Roles",
  "user_profile.search_by_code_or_country": "Search by code or country",
  "user_profile.search_by_email": "Search by email",
  "user_profile.search_by_name": "Search by name",
  "user_profile.send_new_activation_email": "Send new activation email",
  "user_profile.title": "Title",
  "user_profile.titles": "Titles",
  "user_profile.try_another_email": "Please try another email or search by name",
  "user_profile.try_another_name": "Please try another name or search by email",
  "user_profile.unpaid_payments": "Unpaid payments",
  "user_search_form.all_names": "All names except the last name",
  "user_search_form.back": "Back",
  "user_search_form.born": "Born",
  "user_search_form.choose_title": "Choose a title",
  "user_search_form.next": "Next",
  "user_search_form.phone_number_validation_message": "Please enter a valid phone number",
  "user_types.active_members": "Active members",
  "user_types.admin": "Admin",
  "user_types.admins": "Admins",
  "user_types.associate_member": "Associate member",
  "user_types.associate_members": "Associate members",
  "user_types.external_user": "External user",
  "user_types.guardian": "Guardian",
  "user_types.guardians": "Guardians",
  "user_types.member": "Member",
  "user_types.members": "Members",
  "user_types.other": "Other",
  "user_types.others": "Others",
  "user_types.player": "Player",
  "user_types.player_and_admin": "Player and Admin",
  "user_types.players": "Players",
  "waiting_list.1_user_in": "1 user in",
  "waiting_list.add_single_description": "<b>{{ name }}</b> will be added to <b>{{ group }}</b> as a player. Adding this user will exceed the maximum player limit ({{ limit }} players).",
  "waiting_list.add_single_success": "{{ name }} is being added to {{ group }}, this may take some time.",
  "waiting_list.add_single_title": "Add to group?",
  "waiting_list.add_to_group": "Add to group",
  "waiting_list.add_warning_title": "Player limit will be exceeded",
  "waiting_list.add_x_description": "{{ count }} users will be added to <b>{{ group }}</b> as players. Adding these users will exceed the maximum player limit ({{ limit }} players).",
  "waiting_list.add_x_success": "{{ count }} users are being added to {{ group }}, this may take some time.",
  "waiting_list.add_x_title": "Add {{ count }} users to group?",
  "waiting_list.add_x_warning_description": "Activating this option will deactivate \"Member requests\" and move {{ count }} users currently waiting for approval to this waiting list.",
  "waiting_list.create": "Create waiting list",
  "waiting_list.create_description": "To activate waiting list for <b>{{ group }}</b>, please set the players limit and enable waiting list option.",
  "waiting_list.create_warning_description": "Users waiting for approval will be moved here",
  "waiting_list.delete_description": "{{ count }} users will be removed from the waiting list of <b>{{ group }}</b>. They will still be registered in the system and can join the waiting list again.",
  "waiting_list.delete_from_waiting_list": "Remove from waiting list",
  "waiting_list.delete_single_description": "<b>{{ name }}</b> will be removed from the waiting list of <b>{{ group }}</b>. The user will still be registered in the system and can join the waiting list again.",
  "waiting_list.delete_single_success": "{{ userName }} is being removed from the waiting list, this may take some time.",
  "waiting_list.delete_single_title": "Remove from waiting list?",
  "waiting_list.delete_x_success": "{{count}} users are being removed from the waiting list, this may take some time.",
  "waiting_list.delete_x_title": "Remove {{count}} users from waiting list?",
  "waiting_list.disabled": "No waiting list",
  "waiting_list.enable_description": "After reaching players limit, all users joining this group will end up in the waiting list",
  "waiting_list.hint": "Activate waiting list if you want new players to be placed in a queue and be automatically added to the group in the same order they signed up. ",
  "waiting_list.limit_members_description": "Current members won’t be affected",
  "waiting_list.n_users_in": "{{ number }} users in",
  "waiting_list.send_mandatory_payment_title": "Send mandatory payment",
  "welcome_message.get_started": "Get started by downloading our app from the App Store or Google Play.",
  "welcome_message.go_to_dashboard": "Go to the dashboard",
  "welcome_message.hoopit": "Welcome to Hoopit!",
  "welcome_message.hoopit_main_adress": "www.hoopit.io",
  "welcome_message.instruction_for_mobile": "You are invited as a leader and can administer both from mobile app and web. \\n Web is best suited on Pc, you can log in from this address",
  "welcome_message.leader": "If you are a leader, you can manage your team in the web dashboard.",
  "welcome_message.stay_updated_and_logg_in": "Stay updated with you club and team. Download the app and logg inn with phone number and password. ",
  "welcome_message.use_webapp": "You can also use the webapp if you don't want to use mobile app.",
  "welcome_message.you_are_in_waiting_line": "You are now in the waiting line to join {{groupName}}. We will notify you of any updates."
}